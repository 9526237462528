/* eslint-disable */
import { th } from "date-fns/locale";
import React, { Component } from "react";
import { connect } from "react-redux";
import { GET_INSTA_FEED } from "../../../actions";
import infeedtext from "../../../common/images/instafeed-text.png";
class InstaFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insturl: "",
      instaResult: "",
    };
  }

  componentWillMount() {
    this.props.getBannerList();
  }

  componentWillReceiveProps(headerProps) {
    if (Object.keys(headerProps.instafeed).length > 0) {
      this.setState(
        {
          insturl: headerProps.instafeed,
        },
        function () {
          this.displayInsta();
        }
      );
    }
  }
  displayInsta() {
    var instaResult = "";
    if (Object.keys(this.state.insturl).length > 0) {
      {
        var i = 0;
        instaResult = this.state.insturl.map((item, index) => {
          if (item.media_type !== "VIDEO") {
            i++;
            if (i <= 12) {
              return (
                <li>
                  <a
                    href={
                      item.permalink !== "" && item.permalink !== null
                        ? item.permalink
                        : void 0
                    }
                    target="_blank"
                    key={index}
                  >
                    {" "}
                    <img src={item.media_url} />{" "}
                  </a>
                </li>
              );
            }
          }
        });
      }
    }
    this.setState({ instaResult: instaResult });
  }
  render() {
    let bannerArr = this.props.instafeed;
    return (
      <section className="instapro-section">
        {Object.keys(bannerArr).length > 0 && (
          <div className="instahome">
            <div className="container">
              <div className="instahome-inner">
                <div className="hangon-title">
                  {" "}
                  <span>Follow us on</span> <img src={infeedtext} />{" "}
                </div>

                <ul>{this.state.instaResult}</ul>
                <div className="instahome-btm">
                  <a
                    href="https://www.instagram.com/chefsgallery/"
                    target="_blank"
                    className="button"
                  >
                    View More
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

const mapStateTopProps = (state) => {
  var instafeeds = Array();
  if (Object.keys(state.instafeed).length > 0) {
    if (state.instafeed[0]) {
      instafeeds = state.instafeed[0].data;
    }
  }

  return {
    instafeed: instafeeds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerList: () => {
      dispatch({ type: GET_INSTA_FEED });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(InstaFeed);
