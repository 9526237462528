/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { showLoader, hideLoader } from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import cookie from "react-cookies";
import moment from "moment";
import { appId, apiUrl } from "../Helpers/Config";
var qs = require("qs");
class Reservationeditsummary extends Component {
  constructor(props) {
    super(props);
    console.log(this.props, "this.props");
    var bookingdetails = "";
    var existBooking = "";
    if (
      this.props.location.state !== "" &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      bookingdetails = this.props.location.state;
      existBooking = this.props.location.state.editReservation;
    }
    this.state = {
      pagedetail: "",
      reservation_id: existBooking !== "" ? existBooking.reservation_id : "",
      reservation_date:
        bookingdetails !== "" ? bookingdetails.reservation_date : "",
      reservation_time:
        bookingdetails !== "" ? bookingdetails.reservation_time : "",

      reservation_adult:
        bookingdetails !== "" ? bookingdetails.reservation_adult : "",
      reservation_child:
        bookingdetails !== "" ? bookingdetails.reservation_child : "",
      reservation_outlet_name:
        existBooking !== "" ? existBooking.reservation_outlet_name : "",
      reservation_user_name: cookie.load("UserFname"),
      reservation_user_mobile: cookie.load("UserMobile"),
      reservation_user_email: cookie.load("UserEmail"),
      reservation_notes:
        bookingdetails !== "" ? bookingdetails.reservation_notes : "",

      reservation_show_date:
        bookingdetails !== "" ? bookingdetails.reservation_show_date : "",
      reservation_show_time:
        bookingdetails !== "" ? bookingdetails.reservation_show_time : "",
      reservation_end_time:
        bookingdetails !== "" ? bookingdetails.reservation_end_time : "",

      reservation_time_type:
        bookingdetails !== "" ? bookingdetails.reservation_time_type : "",

      reservation_outlet_id:
        existBooking !== "" ? existBooking.reservation_outlet_id : "",
      RerservationStatus: "",
    };
    if (bookingdetails === "") {
      this.props.history.push("/reservation");
    }
  }

  componentWillReceiveProps(nextProps) {}

  reservation_order_submit() {
    showLoader("showLoader", "class");
    var reservation_order = Array();
    reservation_order["app_id"] = appId;
    reservation_order["reservation_id"] = this.state.reservation_id;
    reservation_order["customer_id"] = cookie.load("UserId");
    reservation_order["reservation_outlet_id"] =
      this.state.reservation_outlet_id;
    reservation_order["reservation_no_of_adult_pax"] =
      this.state.reservation_adult;
    reservation_order["reservation_no_of_children_pax"] =
      this.state.reservation_child;
    reservation_order["reservation_instruction"] = this.state.reservation_notes;
    reservation_order["customer_fname"] = cookie.load("UserFname");
    reservation_order["customer_lname"] = cookie.load("UserLname");
    reservation_order["customer_email"] = cookie.load("UserEmail");
    reservation_order["customer_mobile_no"] = cookie.load("UserMobile");

    reservation_order["reservation_date"] =
      this.state.reservation_date + " " + this.state.reservation_show_time;
    reservation_order["reservation_start"] = this.state.reservation_show_time;
    reservation_order["reservation_end"] = this.state.reservation_end_time;

    showLoader("res-cont-div", "class");

    Axios.post(
      apiUrl + "reservation1/reservationupdateorder",
      qs.stringify(reservation_order)
    )
      .then((captureRes) => {
        hideLoader("res-cont-div", "class");
        if (captureRes.data.status === "ok") {
          this.props.history.push("/myorders/reservation");
          return;
        } else {
          this.props.history.push("/myorders/reservation");
        }
      })
      .catch(
        function (error) {
          this.props.history.push("/myorders/reservation");
        }.bind(this)
      );
  }

  render() {
    return (
      <div>
        <div className="common-top-div pagesList-main-div">
          <Header />
          <div className="innersection_wrap">
            <div className="container">
              <div className="reservation-full">
                <div className="row reservation-summary-row">
                  <div className="reservation-summary-all">
                    <h3 class="reservation_text">Reservation</h3>
                    <div className="reservation-summary-full">
                      <h4 class="reservation_sum_text">Reservation Summary</h4>
                      <div className="reservation-summary">
                        <ul>
                          <li>
                            <span>Reservation Date/Time : </span>

                            <span>
                              {this.state.reservation_date}, ({" "}
                              {moment(
                                this.state.reservation_show_time,
                                "HH:mm:ss"
                              ).format("hh:mm A")}{" "}
                              {this.state.reservation_end_time !== "" && (
                                <>
                                  -{" "}
                                  {moment(
                                    this.state.reservation_end_time,
                                    "HH:mm:ss"
                                  ).format("hh:mm A")}{" "}
                                </>
                              )}
                              )
                            </span>
                          </li>
                          <li>
                            <span>No of Pax : </span>

                            <span>
                              {this.state.reservation_adult} Adult,{" "}
                              {this.state.reservation_child} Child
                            </span>
                          </li>
                          <li>
                            <span>Selected Outlet : </span>

                            <span>{this.state.reservation_outlet_name}</span>
                          </li>
                          <li>
                            <span>Name : </span>

                            <span>{this.state.reservation_user_name}</span>
                          </li>
                          <li>
                            <span>Phone No : </span>

                            <span>{this.state.reservation_user_mobile}</span>
                          </li>
                          <li>
                            <span>Email : </span>

                            <span className="summary-email">
                              {this.state.reservation_user_email}
                            </span>
                          </li>
                          <li>
                            <span>Special Request : </span>

                            <span className="summary-childseat">
                              {this.state.reservation_notes}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="reservation-summary-button">
                      <Link className="button" to={"/myorders/reservation"}>
                        Cancel
                      </Link>
                      <a
                        href="javascript:void(0)"
                        className="button showLoader"
                        onClick={this.reservation_order_submit.bind(this)}
                      >
                        Update
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Reservationeditsummary)
);
