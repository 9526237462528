/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
var Parser = require("html-react-parser");
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import innerbanner from "../../common/images/inner-banner.jpg";
class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = { blogList: [], blogTopList: "", bottomBlogList: "" };
    this.loadBlog();
  }

  componentDidMount() {}

  loadBlog() {
    axios
      .get(
        "https://blog.chefsgallery.com.au/index.php?rest_route=/api/v1/getblog/&page=1"
      )
      .then((res) => {
        console.log(res.data, "res.datares.data");
        /* Success response */
        if (res.data.status === "ok") {
          this.setState({ blogList: res.data.data.articles });
          this.dispalyBlog();
        } else {
          $(".dvLoadrCls").hide();
        }
      });
  }

  dispalyBlog() {
    var blogTopList = "";
    var bottomBlogList = "";
    if (this.state.blogList.length > 0) {
      blogTopList = this.state.blogList.map((item, index) => {
        return (
          <li key={index}>
            <a href={item.link} target="_blank">
              {item.title}
            </a>
          </li>
        );
      });

      bottomBlogList = this.state.blogList.map((item, index) => {
        return (
          <li key={index}>
            <div class="press-image">
              <a href={item.link} target="_blank">
                <img src={item.image[0]} />
              </a>
            </div>
            <div class="press-desc">
              <span>{item.date}</span>
              <h6 class="press-title">
                <a href={item.link} target="_blank">
                  {item.title}
                </a>
              </h6>
              <p>
                {item.short_content !== "" && item.short_content !== null
                  ? Parser(item.short_content)
                  : ""}
              </p>
              <a href={item.link} target="_blank" class="inline-link">
                READ MORE
              </a>
            </div>
          </li>
        );
      });
    }
    this.setState(
      { blogTopList: blogTopList, bottomBlogList: bottomBlogList },
      function () {
        $(".dvLoadrCls").hide();
      }
    );
  }

  render() {
    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header />
        {/* Header End */}
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <img src={innerbanner} />
            <p>What's On</p>
          </div>
        </div>

        <div class="container">
          {/*  <div class="press-top">
            <div class="pt-lhs">
              <h2>Blog</h2>
              <p>
                We would love to hear from you. Drop us an email and our team
                will get back to you as soon as possible.
              </p>
              <p>
                Email:{" "}
                <a href="mailto:hello@chefsgallery.com.au">
                  hello@chefsgallery.com.au
                </a>
              </p>
              <a href="#" class="button">
                GALLERY
              </a>
            </div>
            <div class="pt-rhs">
              <h5>Blog List </h5>
              <ul>{this.state.blogTopList}</ul>
              <div class="see-all">
                <a
                  href="https://blog.chefsgallery.com.au"
                  class="loadmore-link"
                >
                  LOAD MORE
                </a>
              </div>
            </div>
          </div> */}
          <div class="press-bottom news_cnt">
            {/*  <h3 class="textcenter">LATEST Blogs</h3> */}
            <ul class="press-list news_list" id="press-list">
              {this.state.bottomBlogList}
            </ul>
            <div class="see-all">
              <a href="https://blog.chefsgallery.com.au" target="blank">
                See all
              </a>
            </div>
          </div>
        </div>

        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    globalsettings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
