/* eslint-disable */
import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { appId, apiUrl } from "../Helpers/Config";
import { validated } from "react-custom-validation";
import validator from "validator";

const isEmpty = (value) => (value === "" ? "This field is required." : null);

function validationConfigSignup(props) {
  const { customer_otp_val } = props.fields;

  return {
    fields: ["customer_otp_val"],

    validations: {
      customer_otp_val: [[isEmpty, customer_otp_val]],
    },
  };
}
class Otpverify extends Component {
  constructor(props) {
    super(props);
  }

  closepopup(path) {
    const { history } = this.props;
    history.push(path);
    $.magnificPopup.close();
  }
  render() {
    const {
      fields,
      onChange,
      onValid,
      onInvalid,
      $field,
      $validation,
    } = this.props;
    const spanStyle = {
      clear: "both",
    };
    let errMsgCustomerOtpVal;

    if ($validation.customer_otp_val.error.reason !== undefined) {
      errMsgCustomerOtpVal = $validation.customer_otp_val.show && (
        <span className="error">
          {$validation.customer_otp_val.error.reason}
        </span>
      );
    }
    return (
      <div className="popup-body">
        <h4>OTP Details</h4>
        <div className="form-group">
          <div
            className={
              fields.customer_otp_val !== "" ? "focus-out focused" : "focus-out"
            }
          >
            <label>OTP</label>
            <input
              type="text"
              className="form-control input-focus"
              value={fields.customer_otp_val}
              {...$field("customer_otp_val", (e) =>
                onChange("customer_otp_val", e.target.value)
              )}
            />
            {errMsgCustomerOtpVal}
            <span
              className="error verify_error"
              style={{ display: "none" }}
            ></span>
          </div>
        </div>
        <div className="form-group">
          <div className="login_pop_sub">
            <button
              type="button"
              className="btn btn_black btn_minwid otpverify_submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              Verify
            </button>
          </div>
        </div>

        <div className="form-group-11">
          <div className="controls single-link">
            <a href="#login-popup" className="open-popup-link">
              Back to login
            </a>
          </div>
        </div>
      </div>
    );
  }
}
Otpverify = validated(validationConfigSignup)(Otpverify);

export default withRouter(Otpverify);
