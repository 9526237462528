/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import cookie from "react-cookies";
import { format } from "date-fns";
import {
  deliveryId,
  pickupId,
  dineinId,
  deliveryName,
  pickupName,
  dineinName,
} from "../Helpers/Config";
import {
  addressFormat,
  stripslashes,
  showPrices,
  hideLoader,
} from "../Helpers/SettingHelper";
import { connect } from "react-redux";
import Moment from "moment";
import {
  GET_CORDERDETAIL,
  GET_PORDERDETAIL,
  GET_PRINTORDER,
  GET_ORDERHISTORY,
  GET_RESERVATIONHISTORY,
} from "../../actions";

/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Sidebar from "./Sidebar";

import scotterImg from "../../common/images/scotter-icon.png";
import mapImg from "../../common/images/map-icon.png";
import innerbanner from "../../common/images/inner-banner.jpg";

var Parser = require("html-react-parser");
class Orders extends Component {
  constructor(props) {
    super(props);
    var userAccountType =
      cookie.load("userAccountType") !== "" &&
      typeof cookie.load("userAccountType") !== undefined &&
      typeof cookie.load("userAccountType") !== "undefined"
        ? cookie.load("userAccountType")
        : "";
    this.state = {
      userAccountType: userAccountType,
      corderdetail: [],
      cartItems: [],
      cartData: [],
      cart_brktime_list: [],
      showitems: 10,
      showpitems: 10,
      showresvitems: 10,
      resviewmore: "none",
      order_all: 0,
      overall_orders: 0,
      deliveryTakeaway_orders: 0,
      currentTab: "C",
      reservation_orders: 0,
      reservationlist: [],
      orderTabs:
        cookie.load("orderTabs") !== "" &&
        typeof cookie.load("orderTabs") !== undefined &&
        typeof cookie.load("orderTabs") !== "undefined"
          ? cookie.load("orderTabs")
          : "",
    };

    if (cookie.load("UserId") === undefined) {
      cookie.save("loginpopupTrigger", "Yes", { path: "/" });
      props.history.push("/");
    }
  }

  componentDidMount() {
    /* delivery current - past orders */
    var deliveryparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getCorderDetail(deliveryparams);

    var deliverypastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getPorderDetail(deliverypastparams);

    /* reservation orders */
    this.props.getReservationHistory(this.state.showresvitems);

    $("#dvLoading").fadeOut(2000);

    //Default Action
    $(".filter_tabsec").hide(); //Hide all content
    $("ul.web_order_typecls li:first").addClass("active").show(); //Activate first tab
    $(".filter_tabsec:first").show(); //Show first tab content

    //On Click Event
    $("ul.web_order_typecls li").click(function () {
      $("ul.web_order_typecls li").removeClass("active"); //Remove any "active" class
      $(this).addClass("active"); //Add "active" class to selected tab
      $(".filter_tabsec").hide(); //Hide all tab content
      var activeTab = $(this).find("a").attr("href"); //Find the rel attribute value to identify the active tab + content
      $(activeTab).fadeIn(); //Fade in the active content
      return false;
    });
    let tabSelect =
      typeof this.props.match.params.tab !== "undefined"
        ? this.props.match.params.tab
        : "";
    console.log(tabSelect, "tabSelecttabSelecttabSelect");
    if (tabSelect === "reservation") {
      $("ul.web_order_typecls li:nth-child(2)").trigger("click");
    } else {
      $("ul.web_order_typecls li:first").addClass("active").show(); //Activate first tab
      $(".filter_tabsec:first").show(); //Show first tab content
    }
  }

  /* for order load more  button */
  loadcurrentItems() {
    var pageNext = this.state.showitems + 10;
    this.setState({ showitems: pageNext }, function () {
      this.loadcitems();
    });
  }
  loadpastItems() {
    var pagepNext = this.state.showpitems + 10;
    this.setState({ showpitems: pagepNext }, function () {
      this.loadpitems();
    });
  }

  loadcitems() {
    $(".load_more_data").append('<b class="gloading_img"></b>');
    var deliveryparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      deliveryId +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getCorderDetail(deliveryparams);
  }
  loadpitems() {
    $(".load_more_data1").append('<b class="gloading_img"></b>');
    var deliverypastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      deliveryId +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getPorderDetail(deliverypastparams);
  }
  loadReservationItems() {
    showLoader("reserv-loader-div", "class");
    var pageNext = this.state.showresvitems + 10;
    this.setState({ showresvitems: pageNext }, function () {
      this.props.getReservationHistory(pageNext);
    });
  }

  componentWillReceiveProps(nextProps) {
    /*activity count -start */
    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          this.setState({
            order_all: nextProps.activitycount[0].result_set.order_all,
            overall_orders:
              nextProps.activitycount[0].result_set.overall_orders,
            deliveryTakeaway_orders:
              nextProps.activitycount[0].result_set.deliveryTakeaway_orders,
            reservation_orders:
              nextProps.activitycount[0].result_set.reservation_orders,
          });
          $("#dvLoading").fadeOut(2000);
        }
      }
    } else {
      this.setState({
        order_all: 0,
        overall_orders: 0,
        deliveryTakeaway_orders: 0,
        reservation_orders: 0,
      });
    }

    /*current orders */
    if (nextProps.corderdetail !== this.props.corderdetail) {
      if (nextProps.corderdetail && nextProps.corderdetail[0].status == "ok") {
        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({ corderdetail: nextProps.corderdetail[0].result_set });
        if (nextProps.corderdetail[0].common.total_records) {
          this.setState({
            totalcount: nextProps.corderdetail[0].common.total_records,
          });
        }
        if (
          this.state.showitems > nextProps.corderdetail[0].common.total_records
        ) {
          $(".load_more_data").hide();
        } else {
          $(".load_more_data").show();
        }
      } else {
        $(".load_more_data").hide();
      }
    }

    if (nextProps.porderdetail !== this.props.porderdetail) {
      if (nextProps.porderdetail && nextProps.porderdetail[0].status == "ok") {
        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({ porderdetail: nextProps.porderdetail[0].result_set });
        if (
          nextProps.porderdetail[0].common.total_records &&
          this.state.showpitems > nextProps.porderdetail[0].common.total_records
        ) {
          $(".load_more_data1").hide();
        } else {
          $(".load_more_data1").show();
        }
      } else {
        $(".load_more_data1").hide();
      }
    }

    if (nextProps.orderhistory !== this.props.orderhistory) {
      if (nextProps.orderhistory[0].status === "ok") {
        this.setState({
          CartItems: nextProps.orderhistory[0].result_set[0]["items"],
          ReceiptDetails: nextProps.orderhistory[0].result_set[0],
        });

        $("#dvLoading").fadeOut(5000);
        setTimeout(function () {
          $.magnificPopup.open({
            items: {
              src: ".receipt_popup",
            },
            type: "inline",
          });
        }, 1000);
      } else {
        this.setState({ ReceiptDetails: [] });
      }
    }

    if (nextProps.printorder !== this.props.printorder) {
      if (nextProps.printorder[0].status === "ok") {
        $("#dvLoading").fadeOut(2000);
        window.open(nextProps.printorder[0].pdf_url, "_blank");
      }
    }

    if (nextProps.reservationlist !== this.state.reservationlist) {
      var displyTxt = "none";
      if (
        this.state.showresvitems < nextProps.reservationcommon.total_records
      ) {
        displyTxt = "inline-block";
      }
      hideLoader("reserv-loader-div", "class");
      this.setState({
        reservationlist: nextProps.reservationlist,
        resviewmore: displyTxt,
      });
    }
  }

  getReceipt(orderId) {
    $("#dvLoading").fadeIn();
    var params =
      "&customer_id=" + cookie.load("UserId") + "&order_id=" + orderId;
    this.props.getOrderHistory(params);
  }

  printReceipt(orderId, availabilityId) {
    $("#dvLoading").fadeIn();
    this.props.getPrintOrder(orderId, deliveryId);
  }

  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm A");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }

    return ordTmSlt;
  }

  getOrderItemData = (dataProp) => {
    var dataProp = dataProp !== undefined ? dataProp : Array();
    if (Object.keys(dataProp).length > 0) {
      return dataProp.map((item) => {
        const orderDate = Moment(item.order_date).format("DD/MM/YYYY");
        /* Advanced Slot */
        const orderTime = this.showOrdTimeSlot(item);
        return (
          <div key={item.order_id} className="current_order">
            <div className="myacc_head_sec">
              <div className="head_left">
                <div className="head-group">
                  <h4>ORDER NO - {item.order_local_no}</h4>
                </div>
              </div>
              <div className="head_right">
                <div className="head-group">
                  <h4>{item.status_name}</h4>
                </div>
              </div>
            </div>

            <div className="order_details_body">
              <div className="order_no_deatails">
                Order placed at :{" "}
                {Moment(item.order_created_on).format("DD/MM/YYYY") +
                  " " +
                  Moment(item.order_created_on).format("h:mm A")}
                <span>Pay by : {item.order_method_name}</span>
              </div>
              {item.order_availability_id === deliveryId ? (
                <div className="delivery_total delivery_total_text">
                  <div className="delivery_total_left">
                    <img src={scotterImg} />
                    <h3>Order Handling By</h3>
                    <span>
                      {item.outlet_name !== "" && item.outlet_name !== null
                        ? stripslashes(item.outlet_name)
                        : ""}
                    </span>
                    <span>
                      {addressFormat(
                        item.outlet_unit_number1,
                        item.outlet_unit_number2,
                        item.outlet_address_line1,
                        item.outlet_address_line2,
                        item.outlet_postal_code
                      )}
                    </span>
                  </div>
                  <div className="delivery_total_left delivery_total_right">
                    <img src={mapImg} />
                    <h3>Home Delivery Location</h3>
                    <span>
                      {addressFormat(
                        item.order_customer_unit_no1,
                        item.order_customer_unit_no2,
                        item.order_customer_address_line1,
                        item.order_customer_address_line2,
                        ""
                      )}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="delivery_total delivery_total_text pickup-order-div">
                  <div className="delivery_total_left">
                    <h3>
                      {item.order_availability_id === pickupId
                        ? pickupName
                        : dineinName}{" "}
                      Location
                    </h3>
                    <span>
                      {item.outlet_name !== "" && item.outlet_name !== undefined
                        ? stripslashes(item.outlet_name)
                        : ""}
                    </span>
                    {item.order_availability_id === dineinId &&
                      item.order_table_number !== "" && (
                        <span>
                          <b>TABLE NUMBER:</b> {item.order_table_number}
                        </span>
                      )}
                    <span>
                      {addressFormat(
                        item.outlet_unit_number1,
                        item.outlet_unit_number2,
                        item.outlet_address_line1,
                        item.outlet_address_line2,
                        item.outlet_postal_code
                      )}
                    </span>
                  </div>
                </div>
              )}
              <div className="delivery_total delivery_total_number">
                <div className="delivery_total_left">
                  <h2>
                    {(() => {
                      if (item.order_availability_id === deliveryId) {
                        return deliveryName;
                      } else if (item.order_availability_id === pickupId) {
                        return pickupName;
                      } else if (item.order_availability_id === dineinId) {
                        return dineinName;
                      } else {
                        return pickupName;
                      }
                    })()}{" "}
                    Date
                  </h2>
                  <h4 className="checkoutDate">{orderDate}</h4>
                </div>
                <div className="delivery_total_left delivery_total_right">
                  <h2>
                    {(() => {
                      if (item.order_availability_id === deliveryId) {
                        return deliveryName;
                      } else if (item.order_availability_id === pickupId) {
                        return pickupName;
                      } else if (item.order_availability_id === dineinId) {
                        return dineinName;
                      } else {
                        return pickupName;
                      }
                    })()}{" "}
                    time
                  </h2>
                  <h4 className="checkoutTime">{orderTime}</h4>
                </div>
              </div>
            </div>

            <div className="order_details_footer">
              <div className="order_amt">
                <div className="order_amt-left">
                  <h3>Order Amount</h3>
                </div>
                <div className="order_amt-right">
                  <h3 className="text-right">
                    {showPrices(item.order_total_amount, "Y")}
                  </h3>
                </div>
              </div>
              <div className="order_btns">
                <a
                  href={void 0}
                  onClick={this.printReceipt.bind(this, item.order_primary_id)}
                  className="button print_invoice"
                  title=""
                >
                  Print Invoice
                </a>
                <a
                  href={void 0}
                  onClick={this.getReceipt.bind(this, item.order_id)}
                  className="button view_recipt"
                  title=""
                >
                  View Receipt
                </a>
              </div>
              {/* )} */}
            </div>
          </div>
        );
      });
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  };

  reservStatus(statusTxt) {
    var returnTxt = "Pending";
    statusTxt = statusTxt !== "" ? parseInt(statusTxt) : 0;
    if (statusTxt === 2) {
      returnTxt = "Cancel";
    } else if (statusTxt === 1) {
      returnTxt = "Assigned";
    } else if (statusTxt === 3) {
      returnTxt = "Arrived";
    } else if (statusTxt === 4) {
      returnTxt = "Not Arrived";
    } else if (statusTxt === 5) {
      returnTxt = "Completed";
    }

    return returnTxt;
  }

  showNoOfPax(adultCount, childCount) {
    var adultTxt =
      parseInt(adultCount) == 0
        ? ""
        : parseInt(adultCount) == 1
        ? adultCount + " Adult"
        : adultCount + " Adults";
    var childTxt =
      parseInt(childCount) == 0
        ? ""
        : parseInt(childCount) == 1
        ? childCount + " Child"
        : childCount + " Children";
    var paxText = adultTxt + " " + childTxt;

    return paxText;
  }
  openActiveTab(currentTab) {
    this.setState({ currentTab: currentTab });
  }

  editReservation(item) {
    this.props.history.push({
      pathname: "/reservation",
      state: { editReservation: item },
    });
  }

  /* reservation order - start*/
  getReservationItemData = (dataProp) => {
    if (Object.keys(dataProp).length > 0) {
      return dataProp.map((item) => {
        const orderDate = Moment(item.reservation_date).format("DD/MM/YYYY");
        var dataTmtxt =
          Moment(item.reservation_date).format("YYYY/MM/DD") +
          " " +
          item.reservation_start_time;
        var dataEndTmtxt =
          Moment(item.reservation_date).format("YYYY/MM/DD") +
          " " +
          item.reservation_end_time;
        const orderTime =
          Moment(dataTmtxt).format("h:mm A") +
          "-" +
          Moment(dataEndTmtxt).format("h:mm A");

        return (
          <div
            key={item.reservation_id}
            className="current_order reserv-orderlist"
          >
            <div className="myacc_head_sec">
              <div className="head_left">
                <div className="head-group">
                  <h4>ORDER NO - {item.reservation_local_order_id}</h4>
                </div>
              </div>
              <div className="head_right">
                <div className="head-group">
                  <h4>{this.reservStatus(item.reservation_status)}</h4>
                </div>
              </div>
            </div>

            <div className="order_details_body">
              {item.reservation_status === "0" && (
                <div className="edit-reservation">
                  <a
                    href={void 0}
                    onClick={this.editReservation.bind(this, item)}
                  >
                    Edit
                  </a>
                </div>
              )}
              <div className="delivery_total delivery_total_number">
                <div className="delivery_total_left">
                  <h2>Date & Time</h2>
                  <h4 className="checkoutDate">
                    {orderDate} {orderTime}
                  </h4>
                </div>
                <div className="delivery_total_left delivery_total_right">
                  <h2>NO OF PAX</h2>
                  <h4 className="checkoutTime">
                    {this.showNoOfPax(
                      item.reservation_no_of_adult_pax,
                      item.reservation_no_of_children_pax
                    )}
                  </h4>
                </div>
              </div>
              <div className="order_no_deatails">
                <h3>Outlet Name</h3>
                <p>{item.reservation_outlet_name}</p>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  };

  render() {
    var settingsMyAcc = {
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 1,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };

    /*	setTimeout(function () {
		//	console.log('render1',$('#dvLoading').length);		
            $('#dvLoading').remove();
						
					}, 500);*/

    return (
      <div className="myacc-main-div">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <img src={innerbanner} />
            <p>My Account</p>
            <span>
              My Account Dashboard allows you to view your recent activities,
              <br />
              check your reward points and update account information.
            </span>
          </div>
        </div>
        <div className="innersection_wrap myadmin_wrap">
          {/* container div - start */}
          <div className="container">
            <div className="myorders">
              <Sidebar currentpage="myorders" />
              <div className="mainacc_menucontent">
                <div className="main_tabsec">
                  <div className="order-tab-section">
                    <div className="mainacc_mobrow">
                      {this.state.userAccountType === "1" ? (
                        <h4 class="form_grouptt">
                          You are logged in as a guest, to view your account
                          information please login into your account with your
                          username and password.
                        </h4>
                      ) : (
                        <div className="tab_sec main_tabsec_inner">
                          <ul className="web_account_menu_cls orc_list orderType web_order_typecls">
                            <li
                              className="active orderTypeli"
                              data-tab="orc-tab1"
                            >
                              <a className="orderNotifi" href="#ordertab1">
                                <h5>Orders </h5>
                                {parseFloat(
                                  this.state.deliveryTakeaway_orders
                                ) > 0 && (
                                  <span
                                    className="count_num_t "
                                    id="orderNotifi"
                                  >
                                    {this.state.deliveryTakeaway_orders}
                                  </span>
                                )}
                              </a>
                            </li>

                            <li
                              data-tab="orc-tab2"
                              className="orderTypeli getReservationCls"
                            >
                              <a
                                className="ReservationorderNotifi"
                                href="#ordertab3"
                              >
                                <h5>Reservation</h5>
                                {parseFloat(this.state.reservation_orders) >
                                  0 && (
                                  <span
                                    className="count_num_t "
                                    id="ReservationorderNotifi"
                                  >
                                    {this.state.reservation_orders}
                                  </span>
                                )}
                              </a>
                            </li>
                          </ul>

                          <div className="myacc_filter">
                            <div
                              className="tab_sec filter_tabsec"
                              id="ordertab1"
                            >
                              <ul className="nav nav-tabs text-center">
                                <li
                                  className={
                                    this.state.currentTab === "C"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <a
                                    data-toggle="tab"
                                    href={void 0}
                                    onClick={this.openActiveTab.bind(this, "C")}
                                    aria-expanded="true"
                                  >
                                    <span>Current Orders</span>
                                  </a>
                                </li>
                                <li
                                  className={
                                    this.state.currentTab === "P"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <a
                                    data-toggle="tab"
                                    href={void 0}
                                    onClick={this.openActiveTab.bind(this, "P")}
                                    aria-expanded="false"
                                  >
                                    <span>Past Orders</span>
                                  </a>
                                </li>
                              </ul>
                              <div className="tab-content">
                                {this.state.currentTab === "C" && (
                                  <div
                                    id="tab-id-inn5"
                                    className="tab-pane fade active in"
                                  >
                                    <h4 className="tab_mobtrigger inner_tab_border active">
                                      Current orders<i></i>
                                    </h4>
                                    <div className="tab_mobrow filter_tabin">
                                      <div className="order-delivery">
                                        <div className="ord-body">
                                          <div className="cur-order-body">
                                            <div className="myacc_order_details">
                                              {this.getOrderItemData(
                                                this.state.corderdetail
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="load_more_div">
                                      <button
                                        className="load_more_data"
                                        onClick={this.loadcurrentItems.bind(
                                          this
                                        )}
                                        style={{ display: "none" }}
                                      >
                                        Load More
                                      </button>
                                    </div>
                                  </div>
                                )}
                                {this.state.currentTab === "P" && (
                                  <div
                                    id="tab-id-inn6"
                                    className="tab-pane fade active in"
                                  >
                                    <h4 className="tab_mobtrigger inner_tab_border active">
                                      Past Orders<i></i>
                                    </h4>
                                    <div className="tab_mobrow filter_tabin">
                                      <div className="pst-order-body">
                                        <div className="myacc_order_details">
                                          {this.getOrderItemData(
                                            this.state.porderdetail
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="load_more_div">
                                      <button
                                        className="load_more_data1"
                                        style={{ display: "none" }}
                                        onClick={this.loadpastItems.bind(this)}
                                      >
                                        Load More
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* next tab */}
                            {/* reservation tab -start */}
                            <div
                              className="tab_sec filter_tabsec reserv-order-main"
                              id="ordertab3"
                            >
                              <div className="tab-content">
                                <div
                                  id="tab-id-inn9"
                                  className="tab-pane fade active in"
                                >
                                  <div className="tab_mobrow filter_tabin">
                                    <div className="order-delivery">
                                      <div className="ord-body">
                                        <div className="cur-order-body">
                                          <div className="myacc_order_details">
                                            {this.getReservationItemData(
                                              this.state.reservationlist
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="load_more_div">
                                    <div className="reserv-loader-div">
                                      <button
                                        className="load_more_resdata"
                                        style={{
                                          display: this.state.resviewmore,
                                        }}
                                        onClick={this.loadReservationItems.bind(
                                          this
                                        )}
                                      >
                                        Load More
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* reservation - end */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* container div - end */}
        </div>

        <Footer />

        <div
          id="receipt-popup"
          className="white-popup mfp-hide popup_sec receipt_popup"
        >
          <div className="pouup_in">
            <Viewreceipt
              details={this.state.ReceiptDetails}
              cartItems={this.state.CartItems}
            />
          </div>
        </div>
        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var reshistoryArr = Array();
  var rescommonArr = Array();
  if (Object.keys(state.reservationhistory).length > 0) {
    if (state.reservationhistory[0].status === "ok") {
      reshistoryArr = state.reservationhistory[0].result_set;
      rescommonArr = state.reservationhistory[0].common;
    }
  }
  return {
    corderdetail: state.corderdetail,
    porderdetail: state.porderdetail,
    printorder: state.printorder,
    orderhistory: state.orderhistory,
    activitycount: state.activitycount,
    reservationlist: reshistoryArr,
    reservationcommon: rescommonArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCorderDetail: (deliveryparams) => {
      dispatch({ type: GET_CORDERDETAIL, deliveryparams });
    },
    getPorderDetail: (deliverypastparams) => {
      dispatch({ type: GET_PORDERDETAIL, deliverypastparams });
    },
    getPrintOrder: (orderprimaryId, availabilityId) => {
      dispatch({ type: GET_PRINTORDER, orderprimaryId, availabilityId });
    },
    getOrderHistory: (params) => {
      dispatch({ type: GET_ORDERHISTORY, params });
    },
    getReservationHistory: (limitTxt) => {
      dispatch({ type: GET_RESERVATIONHISTORY, limitTxt });
    },
  };
};

Orders.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));

class Viewreceipt extends Component {
  constructor(props) {
    super(props);
  }

  getCartDetList(cartItems) {
    if (cartItems.length > 0) {
      return cartItems.map((item, index) => (
        <div className="cart_row product-details oreder_itm_row" key={index}>
          <div className="row-replace">
            <div className="col-sm-cls cart_left text-left">
              <div className="cart_info">
                <h4>
                  {stripslashes(item.item_name)} X {item.item_qty}
                </h4>
                {Object.keys(item.voucher_details).length > 0 && (
                  <div className="cart_extrainfo">
                    <p>
                      <b>Gift Details</b>
                    </p>
                    {item.voucher_details.voucher_gift_name !== "" &&
                      item.voucher_details.voucher_gift_name !== null && (
                        <p>Name: {item.voucher_details.voucher_gift_name}</p>
                      )}
                    {item.voucher_details.order_item_voucher_mobile !== "" &&
                      item.voucher_details.order_item_voucher_mobile !==
                        null && (
                        <p>
                          Mobile:{" "}
                          {item.voucher_details.order_item_voucher_mobile}
                        </p>
                      )}
                    {item.voucher_details.order_item_voucher_email !== "" &&
                      item.voucher_details.order_item_voucher_email !==
                        null && (
                        <p>
                          Email: {item.voucher_details.order_item_voucher_email}
                        </p>
                      )}
                    {item.voucher_details.order_item_voucher_message !== "" &&
                      item.voucher_details.order_item_voucher_message !==
                        null && (
                        <p>
                          Message:{" "}
                          {item.voucher_details.order_item_voucher_message}
                        </p>
                      )}
                  </div>
                )}
                {this.loadModifierItems(
                  "Component",
                  item.modifiers,
                  item.set_menu_component
                )}
              </div>
              {item.item_specification !== "" && (
                <p className="help-block">
                  {stripslashes(item.item_specification)}
                </p>
              )}
              {/*} 
				 <p className ="help-block">Special instruction for individual product items ...</p>
				 {*/}
            </div>
            <div className="col-sm-cls cart_right text-right">
              <div className="cart_price">
                <p>
                  {showPrices(parseFloat(item.item_total_amount).toFixed(2))}
                </p>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';
    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        html +=
          "<p><b>" +
          stripslashes(modName) +
          ":</b></p><p> " +
          stripslashes(modval) +
          "</p>";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);
        html +=
          "<p><b>" +
          comboName +
          ":</b></p><p> " +
          comboVal +
          " " +
          this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) +
          "</p>";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]["menu_product_name"];
        var comboQty = combos[r]["menu_product_qty"];
        var comboPrice = combos[r]["menu_product_price"];
        if (parseFloat(comboPrice) > 0) {
          var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
          html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p>";
        }
      }
      return html;
    }
    return "";
  }
  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "";
    if (lenMod > 0) {
      html = "<div > ";
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["order_modifier_price"];
        if (parseFloat(modValPrice) > 0) {
          var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
          html +=
            "<p><b>" +
            modName +
            ":</b></p><p> " +
            modval +
            newModValPrice +
            "</p>";
        }
      }
      html += "</div>";
    }
    return html;
  }
  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm A");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }

    return ordTmSlt;
  }

  render() {
    const { details, cartItems } = this.props;
    if (details !== undefined) {
      return (
        <div className="Viewreceipt">
          <div className="tnk-you">
            <div className="tnk-header">
              <div className="tnk-detail">
                <div className="tnk-order">
                  <h3>Order No - {details.order_local_no}</h3>
                  <p>
                    Order placed at :{" "}
                    {Moment(details.order_created_on).format(
                      "DD-MM-YYYY hh:mm A"
                    )}
                    <br></br>Pay by : {details.order_method_name}
                  </p>
                </div>
                <h2>YOUR ORDER DETAILS</h2>
              </div>
            </div>
            <div className="tnk-delivery">
              {details.order_availability_id === deliveryId ? (
                <div className="delivery_total">
                  <div className="delivery_total_left">
                    <img src={scotterImg} />
                    <h3>Delivery From</h3>
                    <span>
                      {details.outlet_name !== "" &&
                      details.outlet_name !== undefined
                        ? stripslashes(details.outlet_name)
                        : ""}
                    </span>
                    <span>
                      {addressFormat(
                        details.outlet_unit_number1,
                        details.outlet_unit_number2,
                        details.outlet_address_line1,
                        details.outlet_address_line2,
                        details.outlet_postal_code
                      )}
                    </span>
                  </div>
                  <div className="delivery_total_left delivery_total_right">
                    <img src={mapImg} />
                    <h3>Deliver TO</h3>
                    <span>
                      {details.order_customer_unit_no1 != "" &&
                      details.order_customer_unit_no2 != ""
                        ? "#" +
                          details.order_customer_unit_no1 +
                          "-" +
                          details.order_customer_unit_no2
                        : ""}
                    </span>
                    <span>{details.order_customer_address_line1}</span>{" "}
                  </div>
                </div>
              ) : (
                <div className="delivery_total pickup-order-div">
                  <div className="delivery_total_left">
                    <h3>
                      {details.order_availability_id === pickupId
                        ? pickupName
                        : dineinName}{" "}
                      Location
                    </h3>
                    <span>
                      {details.outlet_name !== "" &&
                      details.outlet_name !== undefined
                        ? stripslashes(details.outlet_name)
                        : ""}
                    </span>
                    {details.order_availability_id === dineinId &&
                      details.order_table_number !== "" && (
                        <span>
                          <b>TABLE NUMBER:</b> {details.order_table_number}
                        </span>
                      )}
                    <span>
                      {addressFormat(
                        details.outlet_unit_number1,
                        details.outlet_unit_number2,
                        details.outlet_address_line1,
                        details.outlet_address_line2,
                        details.outlet_postal_code
                      )}
                    </span>
                  </div>
                </div>
              )}
              <div className="delivery_total delivery_total_number">
                <div className="delivery_total_left">
                  <h2>
                    {(() => {
                      if (details.order_availability_id === deliveryId) {
                        return deliveryName;
                      } else if (details.order_availability_id === pickupId) {
                        return pickupName;
                      } else if (details.order_availability_id === dineinId) {
                        return dineinName;
                      } else {
                        return pickupName;
                      }
                    })()}{" "}
                    Date
                  </h2>
                  <h4 className="checkoutDate">
                    {Moment(details.order_date).format("DD-MM-YYYY")}
                  </h4>
                </div>
                <div className="delivery_total_left delivery_total_right">
                  <h2>
                    {(() => {
                      if (details.order_availability_id === deliveryId) {
                        return deliveryName;
                      } else if (details.order_availability_id === pickupId) {
                        return pickupName;
                      } else if (details.order_availability_id === dineinId) {
                        return dineinName;
                      } else {
                        return pickupName;
                      }
                    })()}{" "}
                    time
                  </h2>
                  {/* Advanced Slot */}
                  <h4 className="checkoutTime">
                    {this.showOrdTimeSlot(details)}
                  </h4>
                </div>
              </div>

              <div className="order-items-maindiv">
                {this.getCartDetList(cartItems)}
                {details.order_remarks !== "" && (
                  <>
                    <div className="remark_notesec text-left">
                      <h4>Remarks</h4>
                      <p>{details.order_remarks}</p>
                    </div>
                    <br />
                  </>
                )}

                {details.order_customer_send_gift === "Yes" && (
                  <>
                    <div className="remark_notesec text-left">
                      <h4>Recipient Name</h4>
                      <p>{details.order_recipient_name}</p>
                    </div>
                    <div className="remark_notesec text-left">
                      <h4>Recipient Contact Number</h4>
                      <p>{details.order_recipient_contact_no}</p>
                    </div>
                    {details.order_gift_message !== "" &&
                      details.order_gift_message !== null && (
                        <div className="remark_notesec text-left">
                          <h4>Gift Message</h4>
                          <p>{details.order_gift_message}</p>
                        </div>
                      )}
                    <br />
                  </>
                )}
              </div>

              <div className="cart_footer tnk_cart_footer">
                <div className="cart_row">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">SUBTOTAL</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>{showPrices(details.order_sub_total)}</span>
                    </div>
                  </div>
                </div>
                {parseFloat(details.order_discount_amount) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {details.order_discount_type === "redeem"
                            ? "Discount (-)"
                            : "Promocode (-)"}
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(details.order_discount_amount).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(details.order_voucher_discount_amount) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">Voucher Discount(-)</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              details.order_voucher_discount_amount
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {parseFloat(details.order_delivery_charge) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">Delivery Charges</p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(details.order_delivery_charge).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                {parseFloat(details.order_additional_delivery) > 0 && (
                  <div className="cart_row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          Additional Delivery Charge
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(
                            parseFloat(
                              details.order_additional_delivery
                            ).toFixed(2)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {details.additionalcharge.length > 0 &&
                  details.additionalcharge.map((item) => {
                    if (parseFloat(item.order_additionalcharge_amount) > 0) {
                      return (
                        <div className="cart_row">
                          <div className="row-replace">
                            <div className="col-sm-cls text-left">
                              <p className="text-uppercase">
                                {item.order_additionalcharge_title}
                              </p>
                            </div>
                            <div className="col-sm-cls text-right">
                              <span>
                                {showPrices(
                                  parseFloat(
                                    item.order_additionalcharge_amount
                                  ).toFixed(2)
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                {details.order_tax_calculate_amount > 0 && (
                  <div className="cart_row gst-row">
                    <div className="row-replace">
                      <div className="col-sm-cls text-left">
                        <p className="text-uppercase">
                          {" "}
                          GST ({details.order_tax_charge}%){" "}
                        </p>
                      </div>
                      <div className="col-sm-cls text-right">
                        <span>
                          {showPrices(details.order_tax_calculate_amount)}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="cart_row cart_footer_totrow grant-total-cls">
                  <div className="row-replace">
                    <div className="col-sm-cls text-left">
                      <p className="text-uppercase">Total</p>
                    </div>
                    <div className="col-sm-cls text-right">
                      <span>{showPrices(details.order_total_amount, "Y")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
