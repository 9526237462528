/* eslint-disable */
var isSubdomain = function (url) {
  var regex = new RegExp(/^([a-z]+\:\/{2})?([\w-]+\.[\w-]+\.\w+)$/);
  return !!url.match(regex);
};

if (location.protocol !== "https:" && !isSubdomain(window.location.host)) {
  var locationULR = location.href.replace("www.", "");
  location.href = locationULR.replace("http://", "https://www.");
} else if (
  location.protocol === "https:" &&
  !isSubdomain(window.location.host)
) {
  var locationULR = location.href;
  if (locationULR.indexOf("www.") < 0) {
    location.href = "https://www." + locationULR.replace("https://", "");
  }
}
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";

import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "./common/css/custom.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";

import Home from "./components/Home/Home";
import Products from "./components/Products/Products";
import ProductDetail from "./components/Products/ProductDetail";
import FavouriteProducts from "./components/Products/FavouriteProducts";
import Checkout from "./components/Checkout/Checkout";
import Thankyou from "./components/Checkout/Thankyou";
import Pages from "./components/Pages/Pages";
import ContactUs from "./components/Pages/ContactUs";
import Outlets from "./components/Pages/Outlets";
import Faq from "./components/Pages/Faq";
import Blog from "./components/Pages/Blog";
import Myaccount from "./components/Myaccount/Myaccount";
import Orders from "./components/Myaccount/Orders";
import Mypromotions from "./components/Myaccount/Mypromotions";
import Myvoucher from "./components/Myaccount/Myvoucher";
import Rewards from "./components/Myaccount/Rewards";
import Account from "./components/Account/Account";
import Resetpassword from "./components/Account/Resetpassword";
import Logout from "./components/Myaccount/Logout";
import Scanqrcode from "./components/Dineqrcode/Scanqrcode";
import Booktable from "./components/Dineqrcode/Booktable";
import Reservation from "./components/Reservation/Reservation";
import Reservationsummary from "./components/Reservation/Reservationsummary";
import Reservationeditsummary from "./components/Reservation/Reservationeditsummary";
import Reservationthankyou from "./components/Reservation/Reservationthankyou";
import Reservationsuggestion from "./components/Reservation/Reservationsuggestion";
import Refpage from "./components/Layout/Refpage";
import Page404 from "./Page404";

const store = getStore();

render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/sign-in" component={Home} />
        <Route path="/scanqrcode" component={Scanqrcode} />
        <Route path="/quickbook/:tablenumber" component={Booktable} />
        <Route
          path={"/menu/:slugType/:slugValue/:proValue"}
          component={ProductDetail}
        />
        <Route path={"/menu/:slugType/:slugValue"} component={Products} />
        <Route path="/menu" component={Products} />
        <Route path="/favourite" component={FavouriteProducts} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/thankyou/:orderId" component={Thankyou} />
        <Route path="/about-us" component={Pages} />
        <Route path="/galleries" component={Pages} />
        <Route path="/our-team" component={Pages} />
        <Route path="/terms-conditions" component={Pages} />
        <Route path="/privacy-policy" component={Pages} />
        <Route path="/page/:page_slug" component={Pages} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/faq" component={Faq} />
        <Route path="/locations" component={Outlets} />
        <Route path="/whats-on" component={Blog} />

        <Route path="/rewards" component={Rewards} />
        <Route path="/mypromotions" component={Mypromotions} />
        <Route path="/voucher" component={Myvoucher} />
        <Route path="/myorders/:tab" component={Orders} />
        <Route path="/myorders" component={Orders} />
        <Route path="/myaccount" component={Myaccount} />
        <Route path="/account/activation/:activationKey" component={Account} />
        <Route
          path="/account/resetpassword/:resetKey"
          component={Resetpassword}
        />
        <Route path="/reservation" component={Reservation} />
        <Route path="/reservation-summary" component={Reservationsummary} />
        <Route
          path="/reservation-edit-summary"
          component={Reservationeditsummary}
        />

        <Route path="/reservation-thankyou" component={Reservationthankyou} />
        <Route
          path="/reservation_suggest/:acceptreject/:resid"
          component={Reservationsuggestion}
        />
        <Route path="/logout" component={Logout} />
        <Route path={"/refpage/:slugtext"} component={Refpage} />
        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
