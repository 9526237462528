/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { connect } from "react-redux";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import FeaturedProducts from "./HomeSubCompo/FeaturedProducts";
import HomeBanner from "./HomeSubCompo/HomeBanner";
import Whatshappening from "./HomeSubCompo/Whatshappening";
import Testimonial from "./HomeSubCompo/Testimonial";
import InstaFeed from "./HomeSubCompo/InstaFeed";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import smartPhone from "../../common/images/smart-phone.png";
import aboutimg from "../../common/images/abt-one.jpg";
import aboutone from "../../common/images/abt-two.jpg";

import odimg2 from "../../common/images/ourdish-img2.jpg";

import {
  projectTitle,
  appId,
  apiUrl,
  deliveryId,
  pickupId,
} from "../Helpers/Config";
import {
  GET_GLOBAL_SETTINGS,
  GET_STATIC_BLOCK,
  GET_NORMAL_POPUP,
  GET_MENU_NAVIGATION,
} from "../../actions";
import {
  showLoader,
  hideLoader,
  validateEmailFun,
  showCustomAlert,
} from "../Helpers/SettingHelper";

import newletterImg from "../../common/images/newletter-bg.jpg";
import { pick } from "underscore";
import { Link } from "react-router-dom";

var Parser = require("html-react-parser");

class Home extends Component {
  constructor(props) {
    super(props);

    var normalpopupFlg = "initial";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes" ||
      cookie.load("promoPopupTrigger") === "Yes"
    ) {
      normalpopupFlg = "trigger";
    }

    this.state = {
      users: [],
      defaultAvilablityId:
        cookie.load("defaultAvilablityId") !== "" &&
        typeof cookie.load("defaultAvilablityId") !== undefined &&
        typeof cookie.load("defaultAvilablityId") !== "undefined"
          ? cookie.load("defaultAvilablityId")
          : "",
      nextavail: "",
      cartTriggerFlg: "No",
      staticblacks: [],
      ourdishes: "",
      viewProductFlg: "no",
      viewProductSlug: "",
      normalpopuplist: [],
      normalpopupdata: [],
      normalpopup_status: "no",
      normalpopup_flg: normalpopupFlg,
      normalpopupstatus: "initial",
      promo_mail_id: "",
      displayType: "all",
      pickup_outlet: [],
      openOutletTime: false,
      promotions: [],
      ourdishes: "",
      testimonial: "",
      ourpromotions: "",
      sojuparty: "",
      homeTestmonial: "",
      navigateMenu: [],
      navigateMenuDisplay: "",
    };

    this.props.getSettings();
    this.props.getMenuNavigation();
    this.props.getNormalPopup();
    this.props.getStaticBlock();
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.navigateMenu !== this.state.navigateMenu) {
      this.setState({ navigateMenu: PropsData.navigateMenu }, function () {
        this.navigateMenuDisplay();
      });
    }
    if (PropsData.staticblack !== this.state.staticblacks) {
      var ourdishes = "";
      var testimonial = "";
      var ourpromotions = "";
      var sojuparty = "";
      var homeTestmonial = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data) => {
          if (data.staticblocks_slug === "our-dishes") {
            ourdishes = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "testimonial") {
            testimonial = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "our-promotions") {
            ourpromotions = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-soju-party-bundles") {
            sojuparty = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-testimonial") {
            homeTestmonial = data.staticblocks_description;
            return "";
          }
        });
      }
      ourdishes =
        ourdishes !== "" && ourdishes !== null ? Parser(ourdishes) : ourdishes;
      testimonial =
        testimonial !== "" && testimonial !== null
          ? Parser(testimonial)
          : testimonial;
      ourpromotions =
        ourpromotions !== "" && ourpromotions !== null
          ? Parser(ourpromotions)
          : ourpromotions;

      sojuparty =
        sojuparty !== "" && sojuparty !== null ? Parser(sojuparty) : sojuparty;
      homeTestmonial =
        homeTestmonial !== "" && homeTestmonial !== null
          ? Parser(homeTestmonial)
          : homeTestmonial;

      this.setState(
        {
          staticblacks: PropsData.staticblack,
          ourdishes: ourdishes,
          testimonial: testimonial,
          ourpromotions: ourpromotions,
          sojuparty: sojuparty,
          homeTestmonial: homeTestmonial,
        },
        function () {
          if (
            cookie.load("UserId") !== "" &&
            typeof cookie.load("UserId") !== undefined &&
            typeof cookie.load("UserId") !== "undefined"
          ) {
            $(".premium-member-action").hide();
          }
        }
      );
    }

    if (
      PropsData.normalpopuplist !== this.state.normalpopuplist &&
      this.state.normalpopup_flg === "initial"
    ) {
      var normalpopupMain = PropsData.normalpopuplist,
        normalpopupdata = [],
        normalpopupStatus = "no";
      if (normalpopupMain !== "") {
        if (Object.keys(normalpopupMain).length > 0) {
          var normalpopupIds = cookie.load("normalpopupIds");
          var normalpopupIdArr =
            normalpopupIds != "" && normalpopupIds != undefined
              ? normalpopupIds.split("~~")
              : Array();
          if (
            $.inArray(normalpopupMain[0].normalpopup_id, normalpopupIdArr) ===
            -1
          ) {
            normalpopupdata = normalpopupMain[0];
            normalpopupStatus = "yes";
          }
          this.setState({
            normalpopuplist: normalpopupMain,
            normalpopupdata: normalpopupdata,
            normalpopup_status: normalpopupStatus,
            normalpopup_flg: "trigger",
          });
        }
      }
    }

    if (PropsData.normalpopupstatus !== this.state.normalpopupstatus) {
      this.setState({ normalpopupstatus: PropsData.normalpopupstatus });
    }
  }

  navigateMenuDisplay() {
    var navigateMenu = "";
    if (this.state.navigateMenu.length > 0) {
      var navigateCommon = this.props.navigateCommon;
      navigateMenu = this.state.navigateMenu.map(function (item, index) {
        return (
          <li key={index}>
            <Link to={"/menu/category/" + item.pro_cate_slug}>
              <figure>
                <img
                  src={
                    item.pro_cate_image !== "" && item.pro_cate_image !== null
                      ? navigateCommon.category_image_url +
                        "/" +
                        item.pro_cate_image
                      : odimg2
                  }
                />
              </figure>
              <figcaption>{item.menu_custom_title}</figcaption>
            </Link>
          </li>
        );
      });
    }
    this.setState({ navigateMenuDisplay: navigateMenu });
  }

  chooseAvailability(availability) {
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      popupIdtxt = "#delevery-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#dinein-popup";
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  componentDidMount() {
    if (
      cookie.load("triggerAvlPop") != "" &&
      cookie.load("triggerAvlPop") != undefined
    ) {
      var availabilityId = cookie.load("triggerAvlPop");
      cookie.remove("triggerAvlPop", { path: "/" });
      this.chooseAvailability(availabilityId);
    }

    if (cookie.load("orderPopuptrigger") === "Yes") {
      cookie.remove("orderPopuptrigger", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("loginpopupTrigger") === "Yes") {
      cookie.save("loginpopupTrigger", "fromcheckout", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    var RctThis = this;
    $("body")
      .off("click", ".act_order_popup")
      .on("click", ".act_order_popup", function (e) {
        e.preventDefault();

        var odrType = $(this).attr("data-acturl");
        var availabilityId = "";
        if (odrType === "delivery") {
          availabilityId = deliveryId;
        } else if (odrType === "pickup") {
          availabilityId = pickupId;
        }

        if (availabilityId !== "") {
          RctThis.chooseAvailability(availabilityId);
        } else if (availabilityId === "" && odrType === "ordernow") {
          $.magnificPopup.open({
            items: {
              src: "#order-popup",
            },
            type: "inline",
          });
        }
      });
    $("body")
      .off("click", ".sign_up_popup")
      .on("click", ".sign_up_popup", function (e) {
        e.preventDefault();
        $.magnificPopup.open({
          items: {
            src: "#signup-popup",
          },
          type: "inline",
        });
      });
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    } else if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductFlg: "yes", viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    } else if (field === "view_pro_upate" && value !== "") {
      this.setState({ viewProductFlg: value });
    } else if (field === "pickup_outlet" && value !== "") {
      this.setState({ pickup_outlet: value });
    } else if (field === "openOutletTime" && value !== "") {
      this.setState({ openOutletTime: value }, function () {});
    }
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }

  triggerNormalPopup(trgType) {
    var otherPageActTrigger = "no";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes"
    ) {
      otherPageActTrigger = "yes";
    }

    if (
      trgType === "loading" &&
      otherPageActTrigger === "no" &&
      this.state.normalpopup_status === "yes" &&
      Object.keys(this.state.normalpopupdata).length > 0 &&
      cookie.load("promoPopupTrigger") !== "Yes"
    ) {
      var normalpopupIds = cookie.load("normalpopupIds");
      var normalpopupIdsNew =
        normalpopupIds != "" && normalpopupIds != undefined
          ? normalpopupIds + "~~" + this.state.normalpopupdata.normalpopup_id
          : this.state.normalpopupdata.normalpopup_id;
      var normalpopupIdArr = [];
      normalpopupIdArr["normalpopupids"] = normalpopupIdsNew;
      cookie.save("normalpopupIds", normalpopupIdsNew, { path: "/" });
      var $_this_rec = this;
      $.magnificPopup.open({
        items: {
          src: "#normal-popup",
        },
        type: "inline",
        midClick: true,
        closeOnBgClick: false,
        callbacks: {
          close: function () {
            $_this_rec.normalPopupUpdate();
          },
        },
      });
    }

    if (
      (cookie.load("promoPopupTrigger") === "Yes" ||
        (otherPageActTrigger === "no" &&
          this.state.normalpopup_status === "no" &&
          Object.keys(this.state.normalpopupdata).length === 0)) &&
      cookie.load("mailpopopuptrg") !== "yes"
    ) {
      cookie.save("mailpopopuptrg", "yes", { path: "/" });
      cookie.remove("promoPopupTrigger", { path: "/" });
      /* $.magnificPopup.open({
        items: {
          src: "#promo-check-popup",
        },
        type: "inline",
        midClick: true,
        closeOnBgClick: false,
      }); */
    }
  }

  normalPopupUpdate() {
    if (cookie.load("mailpopopuptrg") !== "yes") {
      this.props.history.push("/refpage/promopopup");
    }
  }

  handleEmailChange(event) {
    this.setState({ promo_mail_id: event.target.value, promomail_error: "" });
  }

  sendPromoMailFun() {
    var promoEmail = this.state.promo_mail_id;

    var mailErrorTxt = "";
    if (promoEmail === "") {
      mailErrorTxt = "Email Address is required.";
    } else if (!validateEmailFun(promoEmail)) {
      mailErrorTxt = "Invalide Email Address";
    }

    if (mailErrorTxt !== "") {
      this.setState({ promomail_error: mailErrorTxt });
      return false;
    } else {
      showLoader("promomailpopup-cls", "class");

      var qs = require("qs");
      var postObject = {
        app_id: appId,
        email_address: promoEmail,
      };

      axios
        .post(apiUrl + "promotion/user_promotion", qs.stringify(postObject))
        .then((response) => {
          hideLoader("promomailpopup-cls", "class");
          $.magnificPopup.close();
          if (response.data.status === "ok") {
            showCustomAlert(
              "success",
              "You are now a Member of " +
                projectTitle +
                ". Please check your email for more information."
            );
          } else {
            var errMsgtxt =
              response.data.message !== ""
                ? response.data.message
                : "Sorry! You didn`t have promo code.";
            showCustomAlert("error", errMsgtxt);
          }
          return false;
        });
    }

    return false;
  }

  setdisplayType(displayType) {
    this.setState({ displayType: displayType });
  }

  changeAvailability(avilablity, event) {
    event.preventDefault();
    if (
      this.state.defaultAvilablityId === "" ||
      typeof this.state.defaultAvilablityId === undefined ||
      typeof this.state.defaultAvilablityId === "undefined"
    ) {
      var popupIdtxt = "";
      if (avilablity === deliveryId) {
        popupIdtxt = "#delevery-postcode-popup";
      } else if (avilablity === pickupId) {
        popupIdtxt = "#takeaway-popup";
      } else if (availability === pickupId) {
        popupIdtxt = "#dinein-popup";
      }
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    } else if (this.state.defaultAvilablityId !== avilablity) {
      $("#warning_avilablity").val(avilablity);
      $.magnificPopup.open({
        items: {
          src: "#warning-popup",
        },
        type: "inline",
      });
      return false;
    } else {
      this.props.history.push("/menu");
    }
  }

  render() {
    let settingsArr = this.props.globalsettings;
    var showPromoPopup = "",
      showNormalPopup = "";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        if (settingsArr[0].result_set.client_promocode_enable === "1") {
          showNormalPopup =
            settingsArr[0].result_set.client_promo_code_normal_popup_enable ===
            "1"
              ? "yes"
              : "";
          showPromoPopup =
            settingsArr[0].result_set.client_promocode_options === "1"
              ? "yes"
              : "";
        }
      }
    }

    return (
      <div className="home-main-div">
        {/* Header section */}
        <Header
          homePageState={this.state}
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />

        {/* Home banner section */}
        <HomeBanner />

        <div className="content-main">
          {this.state.navigateMenuDispla !== "" && (
            <div className="home-dishes">
              <div className="container">
                {/* {this.state.ourdishes}
                <div class="dish-boxes">
                  <ul>{this.state.navigateMenuDisplay}</ul>
                </div> */}
              </div>
            </div>
          )}
          <div className="home-about">
            <div className="container">
              <div className="home-about-lhs">
                <h3>Welcome to</h3>
                <h4>Chefs Gallery</h4>
                <p>
                  Chefs Gallery is a multi-award winning Chinese restaurant and
                  culinary experience like no other. We meticulously combine
                  traditional Chinese food with pan Asian influences and
                  contemporary flair to create some of the best Chinese in
                  Sydney.
                </p>{" "}
                <a href="/about-us" className="button">
                  Read More
                </a>
              </div>
              <div className="home-about-rhs">
                <span className="htag"> hidden tag</span>
                <img className="harm" src={aboutimg} />
                <img className="harm-half" src={aboutone} />
              </div>
            </div>
          </div>

          {<Whatshappening ourpromotions={this.state.ourpromotions} />}
          <InstaFeed />
          <Testimonial homeTestmonial={this.state.testimonial} />
        </div>
        {/* Footer section */}
        <Footer />

        {/*Normal popup Start*/}
        <div
          id="normal-popup"
          className="white-popup mfp-hide popup_sec normal-popup"
        >
          <div className="normal_popup_alert">
            {this.state.normalpopup_status === "yes" &&
              Object.keys(this.state.normalpopupdata).length > 0 && (
                <div className="normal_popup_cont">
                  {Parser(this.state.normalpopupdata.normalpopup_description)}
                </div>
              )}
          </div>
        </div>
        {/*Normal popup - End*/}

        {/*Promo Check Email popup Start*/}
        <div
          id="promo-check-popup"
          className="white-popup mfp-hide popup_sec promo-check-popup"
        >
          <div className="promopopup-maindiv">
            <div className="promopopup-lhs">
              <div className="frm-action-div">
                <div className="frm-top-title">
                  <h3>Join our email list</h3>
                  <p>Enter your Email address to receive your promocode</p>
                </div>

                <div className="frm-inputbtn-div">
                  <div className="form-group">
                    <div className="focus-out">
                      <label>Email Address</label>
                      <input
                        type="email"
                        className="form-control input-focus"
                        value={this.state.promo_mail_id}
                        onChange={this.handleEmailChange.bind(this)}
                      />
                      {this.state.promomail_error !== "" && (
                        <div id="promomail-error">
                          {this.state.promomail_error}
                        </div>
                      )}
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={this.sendPromoMailFun.bind(this)}
                    className="button promomailpopup-cls"
                  >
                    Subscribe
                  </button>
                </div>

                <div className="frm-bottom-text">
                  <p>
                    SIGN UP FOR EXCLUSIVE UPDATES, NEW PRODUCTS, AND
                    INSIDER-ONLY DISCOUNTS
                  </p>
                </div>
              </div>
            </div>

            <div className="promopopup-rhs">
              <img src={newletterImg} />
            </div>
          </div>
        </div>
        {/*Promo Check Email popup - End*/}

        {this.state.normalpopupstatus !== "initial" &&
          (showNormalPopup === "yes" || showPromoPopup === "yes") &&
          this.triggerNormalPopup("loading")}

        <div
          id="vouchergift-popup"
          className="mfp-hide popup_sec vouchergift-popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-new-header">
              <h3>IM PURCHASING THIS</h3>
            </div>
            <div className="full-login-new-body">
              <div className="popup-footer voucher-popup-body">
                <Tabs>
                  <TabList>
                    <Tab>For Gift</Tab>
                    <Tab>For Me</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="voucher-popup-inner">
                      <h2>GIFT RECEIVER'S INFORMATION</h2>
                      <div className="form-group">
                        <div className="focus-out">
                          <label>Name</label>
                          <input
                            type="text"
                            className="form-control input-focus"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="focus-out">
                          <label>Enter Mobile Number</label>
                          <input
                            type="tel"
                            className="form-control input-focus"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="focus-out">
                          <label>Email Address</label>
                          <input
                            type="text"
                            className="form-control input-focus"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="focus-out">
                          <label>Message</label>
                          <textarea
                            type="text"
                            className="form-control input-focus"
                          />
                        </div>
                      </div>

                      <div className="howtouse-gift-vouchar">
                        <img src={smartPhone} />
                        <h3>How to use</h3>
                        <p>
                          After purchase your recipient can get email
                          notificationand they can use above email address to
                          sign up. Vouchers will be available within their my
                          account{" "}
                        </p>
                      </div>

                      <div className="form-group gift-group">
                        <button type="button" className="button">
                          Add To Cart
                        </button>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="voucher-popup-inner">
                      <button type="button" className="button">
                        Add To Cart
                      </button>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var normalPopupStatus = "initial";
  var normalPopupArr = Array();
  if (Object.keys(state.normalpopuplist).length > 0) {
    if (state.normalpopuplist[0].status === "ok") {
      normalPopupArr = state.normalpopuplist[0].result_set;
      normalPopupStatus = "ok";
    } else {
      normalPopupStatus = "error";
    }
  }

  var tempArr = Array();
  var navigateRst = Array();
  var navigateCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
      }
    }
  }

  return {
    globalsettings: state.settings,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    staticblack: blacksArr,
    normalpopuplist: normalPopupArr,
    normalpopupstatus: normalPopupStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getNormalPopup: () => {
      dispatch({ type: GET_NORMAL_POPUP });
    },
    getMenuNavigation: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
