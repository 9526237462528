/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";
import { GET_ACTIVITYCOUNT } from "../../actions";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overall_orders: 0,
    };
  }

  componentDidMount() {
    this.getActivityCounts();
  }

  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          this.setState({
            order_all: nextProps.activitycount[0].result_set.order_all,
            overall_orders:
              nextProps.activitycount[0].result_set.overall_orders,
            deliveryTakeaway_orders:
              nextProps.activitycount[0].result_set.deliveryTakeaway_orders,
            reservation_orders:
              nextProps.activitycount[0].result_set.reservation_orders,
          });
          $("#dvLoading").fadeOut(2000);
        }
      }
    } else {
      this.setState({
        order_all: 0,
        overall_orders: 0,
        deliveryTakeaway_orders: 0,
        reservation_orders: 0,
      });
    }
  }

  /* Get Redeem Points Count History Details */
  getActivityCounts() {
    const inputKeys = [
      "reward",
      "order_all",
      "overall_orders",
      "deliveryTakeaway_orders",
    ];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }

  /*  get promo category details  */
  getProductIdsDet(cartItems) {
    var product_cartid = "";
    if (Object.keys(cartItems).length > 0) {
      for (var key in cartItems) {
        if (
          product_cartid !== "" &&
          cartItems[key].cart_item_product_id !== ""
        ) {
          product_cartid += ";";
        }
        product_cartid +=
          cartItems[key].cart_item_product_id +
          "|" +
          cartItems[key].cart_item_total_price +
          "|" +
          cartItems[key].cart_item_qty;
      }
    }

    return product_cartid;
  }

  render() {
    var settingsMyAcc = {
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 4,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };

    return (
      <div className="mainacc_menuout">
        <ul className="mainacc_menulist">
          <li
            className={this.props.currentpage === "myaccount" ? "active" : ""}
          >
            <Link to="/myaccount" title="My Account">
              <span>Account Details</span>
            </Link>
          </li>
          <li className={this.props.currentpage === "myorders" ? "active" : ""}>
            <Link to="/myorders" title="My Orders">
              <span>Orders</span>
              {parseFloat(this.state.overall_orders) > 0 && (
                <span id="masterCount">{this.state.overall_orders}</span>
              )}
            </Link>
          </li>
          <li className={this.props.currentpage === "rewards" ? "active" : ""}>
            <Link to="/rewards" title="My Rewards">
              <span>Rewards</span>
            </Link>
          </li>
          <li
            className={
              this.props.currentpage === "mypromotions" ? "active" : ""
            }
          >
            <Link to="/mypromotions" title="My Promotions">
              <span>Promotions</span>
            </Link>
          </li>
          <li className={this.props.currentpage === "voucher" ? "active" : ""}>
            <Link to="/voucher" title="Voucher">
              <span>Voucher</span>
            </Link>
          </li>
          <li>
            <a href="#change-password-popup" className="open-popup-link">
              <span>Change PIN</span>
            </a>
          </li>
          <li>
            <a href="/logout">
              <span>Logout</span>
            </a>
          </li>
        </ul>

        <div className="mbacc_mslidersec mbacc_mslider">
          <Slider {...settingsMyAcc}>
            <div
              className={
                this.props.currentpage === "myaccount"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/myaccount" title="My Account">
                <span>Account Details</span>
              </Link>
            </div>
            <div
              className={
                this.props.currentpage === "myorders"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/myorders" title="My Orders">
                <span>Orders</span>
              </Link>
            </div>
            <div
              className={
                this.props.currentpage === "rewards"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/rewards" title="My Rewards">
                <span>Rewards</span>
              </Link>
            </div>
            <div
              className={
                this.props.currentpage === "mypromotions"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/mypromotions" title="My Promotions">
                <span>Promotions</span>
              </Link>
            </div>

            <div
              className={
                this.props.currentpage === "voucher"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/voucher" title="My Voucher">
                <span>Voucher</span>
              </Link>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activitycount: state.activitycount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
  };
};

Sidebar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
