/* eslint-disable */
import React, { Component } from "react";
import { stripslashes, hideLoader, showLoader } from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
var Parser = require("html-react-parser");
import { connect } from "react-redux";
import { GET_REQUESTPAGEDATA } from "../../actions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import OrderdatetimeSlot from "../Layout/OrderdatetimeSlot";
import OrderAdvancedDatetimeSlot from "../Layout/OrderAdvancedDatetimeSlot";
import axios from "axios";
import { format } from "date-fns";

import reserveImg from "../../common/images/cg-calender.png";

/*** DEV SK***/
import { appId, reservationId, CountryTxt, apiUrlV2 } from "../Helpers/Config";
import { GET_GLOBAL_SETTINGS, GET_ALL_OUTLETS } from "../../actions";
import cookie from "react-cookies";
import moment from "moment";

const settingsGallery = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  boolean: true,
};

class Reservation extends Component {
  constructor(props) {
    super(props);
    //console.log(this.props, "this.props");
    var Maxdate = new Date();
    Maxdate.setFullYear(Maxdate.getFullYear() - 10);

    var editReservation = "";
    if (
      this.props.location.state !== "" &&
      typeof this.props.location.state !== undefined &&
      typeof this.props.location.state !== "undefined"
    ) {
      editReservation = this.props.location.state.editReservation;
      if (editReservation.reservation_status !== "0") {
        editReservation = "";
      }
    }
    console.log(editReservation, "editReservation");
    this.state = {
      editReservation: editReservation,
      timeSlotHtmlDrwn: "",
      slctTimeValue: "",
      Maxdate: Maxdate,
      outletlist: [],
      reservation_outlet_id: "",
      reservation_outlet_txt: "",
      reservation_selected_outlet: "",
      reservation_outlet_postalcode: "",
      reservation_outlet_name: "",
      reservation_outletaddress_line: "",
      reservation_outlet_address: "",
      adultValue: 0,
      childValue: 0,
      datePicker: 1,
      timePicker: 1,
      reservation_notes: "",
      datePickershow: 0,
      timePickershow: 0,
      reservation_flag_key: "",
      reservation_flag_date: "",
      reservation_date_pre: "",
      reservation_date_post: "",
      dateListArr: "",
      current_day: "",
      user_reservation_date: "",
      dineInfo: [],
      seleted_ord_date: "",
      seleted_ord_time: "",
      user_reservation_time:
        typeof cookie.load("reservation_show_time") != "undefined" &&
        cookie.load("reservation_show_time") != ""
          ? cookie.load("reservation_show_time")
          : "",
      user_reservation_show_date: "",
      user_reservation_time_type:
        typeof cookie.load("reservation_time_type") != "undefined" &&
        cookie.load("reservation_time_type") != ""
          ? cookie.load("reservation_time_type")
          : "",
      user_reservation_date_array: "",
      outletadultLimit: "",
      outletchildLimit: "",
      outletpaxLimit: "",
      ResAllOutletDropDownHtml: "",
    };

    var avilablityIdTxt = cookie.load("defaultAvilablityId");
  }

  async componentDidMount() {
    await this.props.getAllOutlets(reservationId);
    if (
      typeof cookie.load("reservation_adult") != "undefined" &&
      typeof cookie.load("reservation_adult") != ""
    ) {
      this.setState({ adultValue: cookie.load("reservation_adult") });
    }
    if (
      typeof cookie.load("reservation_child") != "undefined" &&
      typeof cookie.load("reservation_child") != ""
    ) {
      this.setState({ childValue: cookie.load("reservation_child") });
    }
    if (
      typeof cookie.load("reservation_selected_outlet") != "undefined" &&
      typeof cookie.load("reservation_selected_outlet") != ""
    ) {
      var Detailsevent = {
        target: {
          name: "reservation_outlet_id",
          value: cookie.load("reservation_selected_outlet"),
        },
      };
      this.handleChange(Detailsevent);
    }

    if (
      typeof cookie.load("reservation_notes") != "undefined" &&
      typeof cookie.load("reservation_notes") != ""
    ) {
      this.setState({ reservation_notes: cookie.load("reservation_notes") });
    }

    $(".error_div_cls_limit").hide();
    $(".error_div_cls").hide();
    if (this.state.editReservation !== "") {
      var orderDateTime =
        this.state.editReservation.reservation_date.split(" ");

      cookie.save("orderDateTime", orderDateTime[0] + "T00:00::00.000Z");

      // cookie.save("orderDateTime", converTime);
      var reservation_show_date = moment(
        new Date(this.state.editReservation.reservation_date),
        ["h:mm A"]
      ).format("DD-MM-YYYY");
      var deliveryDate = moment(
        new Date(this.state.editReservation.reservation_date),
        ["h:mm A"]
      ).format("DD/MM/YYYY");
      cookie.save("reservation_show_date", reservation_show_date);
      cookie.save("reservation_date", reservation_show_date);
      cookie.save("deliveryDate", deliveryDate);

      cookie.save(
        "deliveryTime",
        this.state.editReservation.reservation_start_time
      );
      cookie.save(
        "reservation_time",
        this.state.editReservation.reservation_start_time
      );
      cookie.save(
        "reservation_show_time",
        this.state.editReservation.reservation_start_time
      );
      cookie.save(
        "reservation_end_time",
        this.state.editReservation.reservation_end_time
      );
      cookie.save("reservation_time_type", 1);
      var orderSlotVal =
        this.state.editReservation.reservation_start_time +
        " - " +
        this.state.editReservation.reservation_end_time;
      orderSlotVal =
        orderSlotVal +
        "/" +
        moment(
          new Date(
            orderDateTime[0] +
              " " +
              this.state.editReservation.reservation_start_time
          ),
          ["h:mm A"]
        ).format("h:mm A") +
        " - " +
        moment(
          new Date(
            orderDateTime[0] +
              " " +
              this.state.editReservation.reservation_end_time
          ),
          ["h:mm A"]
        ).format("h:mm A");
      cookie.save("orderSlotVal", orderSlotVal);

      /*cookie.save(""); */

      this.setState({
        adultValue: this.state.editReservation.reservation_no_of_adult_pax,
        childValue: this.state.editReservation.reservation_no_of_children_pax,
        reservation_notes: this.state.editReservation.reservation_specification,
        reservation_selected_outlet:
          this.state.editReservation.reservation_outlet_id,
        outletadultLimit: this.state.editReservation.outlet_adult_limit,
        outletchildLimit: this.state.editReservation.outlet_child_limit,
        outletpaxLimit: this.state.editReservation.outlet_max_pax_limit,
        getDateTimeFlg: "yes",
        order_tat_time: this.state.editReservation.outlet_delivery_timing,
        seletedAvilablityId: reservationId,
        seletedOutletId: this.state.editReservation.reservation_outlet_id,
        reservation_date: reservation_show_date,
        reservation_show_date: reservation_show_date,
        reservation_show_time:
          this.state.editReservation.reservation_start_time,
        reservation_time: this.state.editReservation.reservation_start_time,
        reservation_end_time: this.state.editReservation.reservation_end_time,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.alloutletslist !== nextProps.alloutletslist) {
      var ResOutletHtml = "<option value=''> Select Outlet </option>";
      var outletArr = nextProps.alloutletslist;
      if (Object.keys(outletArr).length > 0) {
        outletArr.map(
          (loadData) =>
            (ResOutletHtml +=
              loadData.outlet_name !== "Pantry"
                ? "<option value='" +
                  loadData.oa_outlet_id +
                  "~" +
                  loadData.outlet_postal_code +
                  "~" +
                  stripslashes(loadData.outlet_name).replace("'", "`") +
                  "~" +
                  stripslashes(loadData.outlet_address_line1) +
                  "~" +
                  loadData.outlet_delivery_timing +
                  "~" +
                  loadData.outlet_adult_limit +
                  "~" +
                  loadData.outlet_child_limit +
                  "~" +
                  loadData.outlet_max_pax_limit +
                  "' >" +
                  stripslashes(loadData.outlet_name) +
                  "</option>"
                : "")
        );

        if (outletArr.length == 1) {
          var addrsTxt =
            stripslashes(outletArr[0].outlet_address_line1) +
            " " +
            CountryTxt +
            ", " +
            outletArr[0].outlet_postal_code;
          this.setState({
            reservation_outlet_id: outletArr[0].oa_outlet_id,
            reservation_outlet_postalcode: outletArr[0].outlet_postal_code,
            reservation_outlet_name: stripslashes(outletArr[0].outlet_name),
            reservation_outletaddress_line: stripslashes(
              outletArr[0].outlet_address_line1
            ),
            reservation_outlet_address: addrsTxt,
            is_single_outlet: "Yes",
          });
        }
      }
      var outletDropDown = Parser(ResOutletHtml);
      var isSngl = outletArr.length == 1 ? "Yes" : "No";
      this.setState({
        outletlist: nextProps.alloutletslist,
        ResAllOutletDropDownHtml: outletDropDown,
      });
    }
  }

  /* check outlet is available or not */
  checkOutletAvailability(outletArr, isSingleOutlet) {
    document.getElementById("no_outlet_info").style.display = "none";
    document.getElementById("outlet_list_div").style.display = "block";
    if (outletArr === 0) {
      document.getElementById("no_outlet_info").style.display = "block";
      document.getElementById("outlet_list_div").style.display = "none";
    } else if (outletArr === 1 && isSingleOutlet === "Yes") {
      document.getElementById("outlet_list_div").style.display = "none";
    }
  }

  setOrderOutletDateTimeData() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var orderInfoData =
        this.state.seletedAvilablityId === reservationId
          ? this.state.dineInfo
          : this.state.deliveryInfo;
      if (Object.keys(orderInfoData).length > 0) {
        var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
        var OrderHours = seletedOrdTime.getHours();
        var OrderMunts = seletedOrdTime.getMinutes();
        var OrderSecnd = seletedOrdTime.getSeconds();
        var orderDateTime = new Date(OrderDate);
        orderDateTime.setHours(OrderHours);
        orderDateTime.setMinutes(OrderMunts);
        orderDateTime.setSeconds(OrderSecnd);

        var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
        var deliveryTime =
          this.convPad(OrderHours) +
          ":" +
          this.convPad(OrderMunts) +
          ":" +
          this.convPad(OrderSecnd);

        cookie.save("orderDateTime", orderDateTime);
        cookie.save("deliveryDate", deliveryDate);
        cookie.save("deliveryTime", deliveryTime);

        cookie.save("orderOutletId", orderInfoData["orderOutletId"]);
        cookie.save("orderOutletName", orderInfoData["orderOutletName"]);
        cookie.save("orderPostalCode", orderInfoData["orderPostalCode"]);
        cookie.save("orderTAT", orderInfoData["orderTAT"]);
        cookie.save("orderHandled", orderInfoData["orderHandled"]);
        cookie.save(
          "defaultAvilablityId",
          orderInfoData["defaultAvilablityId"]
        );
        cookie.save("orderHandledByText", orderInfoData["orderHandledByText"]);
        cookie.save("outletchosen", orderInfoData["defaultAvilablityId"]);
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  handleChange(event) {
    if (event.target.name === "reservation_outlet_id") {
      this.resetErrorClass();
      this.setState({ reservation_date: "", reservation_time: "" });

      showLoader("datepickers_main_div", "class");

      var ouLtvl = event.target.value;

      this.setState({ reservation_selected_outlet: ouLtvl });
      var outletIdvl = "",
        outletAddrsLn = "",
        outletAddrs = "",
        outletPostCd = "",
        outletName = "",
        errorInfo = "block",
        temHlval = "",
        outletTatTm = "",
        outletadultLimit = "",
        outletchildLimit = "",
        outletpaxLimit = "";

      if (ouLtvl !== "") {
        var splitedOutletArr = ouLtvl.split("~");
        outletIdvl = splitedOutletArr[0];
        outletPostCd = splitedOutletArr[1];
        outletName = splitedOutletArr[2];
        outletAddrsLn = splitedOutletArr[3];
        outletTatTm = splitedOutletArr[4];
        outletadultLimit = splitedOutletArr[5];
        outletchildLimit = splitedOutletArr[6];
        outletpaxLimit = splitedOutletArr[7];
        outletAddrs = outletAddrsLn + " " + CountryTxt + ", " + outletPostCd;
        errorInfo = "none";

        hideLoader("datepickers_main_div", "class");
      }

      this.setState({
        reservation_outlet_id: outletIdvl,
        reservation_outlet_postalcode: outletPostCd,
        reservation_outlet_name: outletName,
        reservation_outletaddress_line: outletAddrsLn,
        reservation_outlet_address: outletAddrs,
        datePickershow: 1,
        outletadultLimit: outletadultLimit,
        outletchildLimit: outletchildLimit,
        outletpaxLimit: outletpaxLimit,
      });

      var seletedOutletId = outletIdvl;

      var orderHandled = outletName;

      var dineInfo = [];
      dineInfo["orderOutletId"] = outletIdvl;
      dineInfo["orderOutletName"] = stripslashes(outletName);
      dineInfo["orderPostalCode"] = outletPostCd;
      dineInfo["orderTAT"] = outletTatTm;
      dineInfo["orderHandled"] = orderHandled;
      dineInfo["orderHandledByText"] = orderHandled;
      dineInfo["defaultAvilablityId"] = reservationId;

      this.setState({
        dineInfo: dineInfo,
        seletedAvilablityId: reservationId,
        seletedOutletId: outletIdvl,
        order_tat_time: outletTatTm,
        orderHandled: orderHandled,
      });
      console.log(seletedOutletId, "seletedOutletIdseletedOutletId");
      var dineInfo = this.state.dineInfo;
      if (seletedOutletId !== "") {
        axios
          .get(
            apiUrlV2 +
              "settings/chkTimeslotIsAvaiable?app_id=" +
              appId +
              "&availability_id=" +
              reservationId +
              "&outletId=" +
              seletedOutletId
          )
          .then((res) => {
            if (res.data.status === "success") {
              this.setState({ getDateTimeFlg: "yes" });
            } else {
              $(document).find(".order_date_value,.order_time_value").val("");
            }
          });
      } else {
        $(".outlet_error").html(
          '<span class="error"> Please choose one outlet.</span>'
        );
      }

      document.getElementById("error_info_div").style.display = errorInfo;
    }

    if (event.target.name === "reservation_notes") {
      this.setState({ reservation_notes: event.target.value });
    }
  }

  updateAdultQty(type) {
    if (this.state.reservation_selected_outlet === "") {
      $("#error_info_div").show();
      return false;
    } else {
      $("#error_info_div").hide();
    }

    var currentAdult = this.state.adultValue;
    var currentChild = this.state.childValue;
    var maxAdult = this.state.outletadultLimit;
    var paxlimit = this.state.outletpaxLimit;
    var newValue = "";

    if (type === "increment") {
      if (maxAdult != null) {
        if (
          parseInt(currentAdult) < maxAdult &&
          parseInt(currentAdult) + parseInt(currentChild) < paxlimit
        ) {
          $(".error_div_cls_limit").hide();
          newValue = parseInt(currentAdult) + 1;
        } else {
          $(".error_div_cls_limit").show();
          newValue = parseInt(currentAdult);
        }
      } else {
        newValue = parseInt(currentAdult) + 1;
      }
    } else if (type === "decrement") {
      $(".error_div_cls_limit").hide();
      if (parseInt(currentAdult) > 0) {
        newValue = parseInt(currentAdult) - 1;
      } else {
        newValue = 0;
      }
    }
    $(".error_div_cls").hide();
    this.setState({ adultValue: newValue });
    setTimeout(function () {
      $(".error_div_cls_limit").hide();
    }, 3000);
  }

  convPad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  updateChildtQty(type) {
    if (this.state.reservation_selected_outlet === "") {
      $("#error_info_div").show();
      return false;
    } else {
      $("#error_info_div").hide();
    }
    var currentChild = this.state.childValue;
    var currentAdult = this.state.adultValue;
    var maxChild = this.state.outletchildLimit;
    var paxlimit = this.state.outletpaxLimit;
    var newValue = "";
    if (type === "increment") {
      if (maxChild != null) {
        if (
          parseInt(currentChild) < maxChild &&
          parseInt(currentAdult) + parseInt(currentChild) < paxlimit
        ) {
          $(".error_div_cls_limit").hide();
          newValue = parseInt(currentChild) + 1;
        } else {
          $(".error_div_cls_limit").show();
          newValue = parseInt(currentChild);
        }
      } else {
        newValue = parseInt(currentChild) + 1;
      }
    } else if (type === "decrement") {
      $(".error_div_cls_limit").hide();
      if (parseInt(currentChild) > 0) {
        newValue = parseInt(currentChild) - 1;
      } else {
        newValue = 0;
      }
    }
    $(".error_div_cls").hide();
    this.setState({ childValue: newValue });
    setTimeout(function () {
      $(".error_div_cls_limit").hide();
    }, 3000);
  }

  submit_reservation() {
    if (this.state.editReservation !== "") {
      var reservation_time = moment(this.state.user_reservation_time, [
        "h:mm A",
      ]).format("HH:mm:ss");
      var seleted_ord_date = moment(this.state.seleted_ord_date, [
        "h:mm A",
      ]).format("DD-MM-YYYY");
      var seleted_ord_time_view = moment(this.state.seleted_ord_time, [
        "h:mm A",
      ]).format("HH:mm:ss");
      var seleted_ord_time =
        moment(this.state.seleted_ord_time, ["h:mm A"]).format("HH:mm:ss") +
        "-" +
        moment(this.state.seleted_ord_time, ["h:mm A"])
          .add(1, "hours")
          .format("HH:mm:ss");

      var bookingDetails = {
        editReservation: this.state.editReservation,
        reservation_adult: this.state.adultValue,
        reservation_child: this.state.childValue,
        reservation_notes: this.state.reservation_notes,
        reservation_date: seleted_ord_date,
        reservation_show_date: seleted_ord_date,
        reservation_show_time: seleted_ord_time_view,
        reservation_time: this.state.seleted_ord_slot_str,
        reservation_end_time: this.state.seleted_ord_slot_end,
        reservation_time_type: 1,
      };
      this.props.history.push({
        pathname: "/reservation-edit-summary",
        state: bookingDetails,
      });
    } else {
      if (this.setOrderOutletDateTimeData()) {
        var adultValue = this.state.adultValue;
        var childValue = this.state.childValue;

        if (parseInt(adultValue) === 0 && parseInt(childValue) === 0) {
          $(".error_div_cls").show();
          return false;
        }

        if (this.state.reservation_outlet_id === "") {
          $("#error_info_div").show();
          return false;
        }

        if (
          $(document).find(".order_date_value").val() == "" ||
          $(document).find(".order_date_value").val() == ""
        ) {
          $("#error_info_div").show();
          return false;
        }

        var reservation_time = moment(this.state.user_reservation_time, [
          "h:mm A",
        ]).format("HH:mm:ss");
        var seleted_ord_date = moment(this.state.seleted_ord_date, [
          "h:mm A",
        ]).format("DD-MM-YYYY");
        var seleted_ord_time_view = moment(this.state.seleted_ord_time, [
          "h:mm A",
        ]).format("HH:mm:ss");
        var seleted_ord_time =
          moment(this.state.seleted_ord_time, ["h:mm A"]).format("HH:mm:ss") +
          "-" +
          moment(this.state.seleted_ord_time, ["h:mm A"])
            .add(1, "hours")
            .format("HH:mm:ss");

        var bookingDetails = {
          reservation_date: seleted_ord_date,
          reservation_show_date: seleted_ord_date,
          reservation_show_time: seleted_ord_time_view,
          reservation_time: this.state.seleted_ord_slot_str,
          reservation_end_time: this.state.seleted_ord_slot_end,
          reservation_time_type: 1,
          reservation_adult: this.state.adultValue,
          reservation_child: this.state.childValue,
          reservation_outlet_id: this.state.reservation_outlet_id,
          reservation_outlet_name: this.state.reservation_outlet_name,
          reservation_selected_outlet: this.state.reservation_selected_outlet,
          reservation_notes: this.state.reservation_notes,
          reservation_date_array: this.state.user_reservation_date_array,
          reservation_time_flag_next: this.state.time_flag_next,
          reservation_time_flag: this.state.flag_time,
          reservation_date_flag_keySet: this.state.flag_keySet,
          reservation_date_flag_dateSet: this.state.flag_dateSet,
        };

        cookie.save("reservation_date", seleted_ord_date);
        cookie.save("reservation_show_date", seleted_ord_date);
        cookie.save("reservation_show_time", seleted_ord_time_view);
        cookie.save("reservation_time", this.state.seleted_ord_slot_str);
        cookie.save("reservation_end_time", this.state.seleted_ord_slot_end);
        cookie.save("reservation_time_type", "1");
        cookie.save("reservation_adult", this.state.adultValue);
        cookie.save("reservation_child", this.state.childValue);
        cookie.save("reservation_outlet_id", this.state.reservation_outlet_id);
        cookie.save(
          "reservation_outlet_name",
          this.state.reservation_outlet_name
        );
        cookie.save(
          "reservation_selected_outlet",
          this.state.reservation_selected_outlet
        );
        cookie.save("reservation_notes", this.state.reservation_notes);
        cookie.save(
          "reservation_date_array",
          this.state.user_reservation_date_array
        );
        cookie.save("reservation_time_flag_next", this.state.time_flag_next);
        cookie.save("reservation_time_flag", this.state.flag_time);
        cookie.save("reservation_date_flag_keySet", this.state.flag_keySet);
        cookie.save("reservation_date_flag_dateSet", this.state.flag_dateSet);

        var UserId =
          cookie.load("UserId") != "" && cookie.load("UserId") != undefined
            ? cookie.load("UserId")
            : "";
        if (UserId === "") {
          /* cookie.save("loginpopupTrigger", "Yes");
          cookie.save("redirectReservatin", "Yes"); */
          $.magnificPopup.open({
            items: {
              src: "#login-popup",
            },
            type: "inline",
          });
          //  this.props.history.push("/");
        } else {
          this.props.history.push({
            pathname: "/reservation-summary",
            state: bookingDetails,
          });
        }
      } else {
        $("#error_info_div").show();
        return false;
      }
    }
  }

  resetErrorClass() {
    $(
      ".choose_event_data, .date_list_maindiv, .breaktime_main_div"
    ).removeClass("pkgerror_out");
    $(
      ".choose_event_data, .date_list_maindiv, .breaktime_main_div"
    ).removeClass("bdr_bf_select");
    $(".choose_event_data, .date_list_maindiv, .breaktime_main_div").addClass(
      "bdr_bf_select"
    );
  }

  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
      var OrdDateTimeArr = Array();
      OrdDateTimeArr["OrdDate"] = tmSltArr["sldorddate"];
      OrdDateTimeArr["OrdTime"] = tmSltArr["sldordtime"];
    } else if (field == "ordSlotDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: tmSltArr["ordSlotVal"],
        seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
        seleted_ord_slot_str: tmSltArr["ordSlotStr"],
        seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
      });
      var OrdDateTimeArr = Array();
      OrdDateTimeArr["OrdDate"] = tmSltArr["sldorddate"];
      OrdDateTimeArr["OrdTime"] = tmSltArr["sldordtime"];
    }
  };

  sateValChange = (field, value) => {
    if (field === "proceedreservertion" && value === "Yes") {
      this.submit_reservation();
    }
  };

  render() {
    let defaultAvilTyId = cookie.load("defaultAvilablityId");
    var settingsArr = this.props.globalsettings;
    var advancedTimeslotEnable = "0";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        advancedTimeslotEnable =
          settingsArr[0].result_set.client_advanced_timeslot_enable;
      }
    }

    return (
      <div>
        <div className="common-top-div reservation-main-div">
          <Header sateValChange={this.sateValChange} />
          <div className="container innersection_wrap">
            <div className="reservation_step_one">
              <div className="reservation-full">
                <img className="res-fullimg" src={reserveImg} />
                <h3>Reservation</h3>
                <div className="reserve-details" id="reservation">
                  <h4>
                    {this.state.editReservation !== "" && "Edit "}Reservation
                    Details
                  </h4>
                  <p>
                    Please note that bookings of more than 10 pax may have a
                    minimum spend. We regret that we can only hold your table
                    for 15 minutes.
                  </p>
                  <br />
                  {this.state.editReservation !== "" ? (
                    <>
                      <div className="edit-outlet-detials">
                        Reservation Outlet :{" "}
                        <b>
                          {this.state.editReservation.reservation_outlet_name}
                        </b>
                        <br />
                        Reservation Date :{" "}
                        <b>
                          {" "}
                          {moment(
                            this.state.editReservation.reservation_date
                          ).format("DD-MM-YYYY")}{" "}
                          {moment(
                            this.state.editReservation.reservation_start_time,
                            "HH:mm:ss"
                          ).format("hh:mm A")}{" "}
                          {this.state.editReservation.reservation_end_time !==
                            "" && (
                            <>
                              -{" "}
                              {moment(
                                this.state.editReservation.reservation_end_time,
                                "HH:mm:ss"
                              ).format("hh:mm A")}{" "}
                            </>
                          )}
                        </b>
                      </div>
                      <div id="error_info_div" className="errorInfo-div">
                        <i
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                        <span id="error error_span">
                          {" "}
                          Please select the Outlet and Date/Time.
                        </span>
                      </div>
                      <div className="form-group">
                        <div className="choose-datetime-full">
                          <OrderAdvancedDatetimeSlot
                            {...this.props}
                            hdrState={this.state}
                            setdateTimeFlg={this.setdateTimeFlg}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="choose-outlet">
                        <div id="no_outlet_info" className="errorInfo-div">
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          <span id="info_span">
                            {" "}
                            Sorry!. Outlets didn't available for catering..
                          </span>
                        </div>
                        <div id="error_info_div" className="errorInfo-div">
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          <span id="error error_span">
                            {" "}
                            Please select the Outlet and Date/Time.
                          </span>
                        </div>
                        <div
                          className="form-group custom_select choose_event_data bdr_bf_select"
                          id="outlet_list_div"
                        >
                          <select
                            value={this.state.reservation_selected_outlet}
                            name="reservation_outlet_id"
                            className="form-control"
                            onChange={this.handleChange.bind(this)}
                          >
                            {this.state.ResAllOutletDropDownHtml}
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="choose-datetime-full">
                          {advancedTimeslotEnable === "1" ? (
                            <OrderAdvancedDatetimeSlot
                              {...this.props}
                              hdrState={this.state}
                              setdateTimeFlg={this.setdateTimeFlg}
                            />
                          ) : (
                            <OrderdatetimeSlot
                              {...this.props}
                              hdrState={this.state}
                              setdateTimeFlg={this.setdateTimeFlg}
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="form-group chef-res-full">
                    <div className="chef-res-ppl">
                      <div className="res-pm">
                        <p>Pax</p>
                      </div>

                      <div className="qty_bx">
                        <span
                          className="qty_minus"
                          onClick={this.updateAdultQty.bind(this, "decrement")}
                        >
                          -
                        </span>
                        <input
                          type="text"
                          name="adultValue"
                          value={this.state.adultValue}
                          readOnly
                        />
                        <span
                          className="qty_plus"
                          onClick={this.updateAdultQty.bind(this, "increment")}
                        >
                          +
                        </span>
                      </div>
                    </div>
                    <div className="chef-res-ppl">
                      <div className="res-pm">
                        <p>High Chair&nbsp;&nbsp;&nbsp;</p>
                      </div>

                      <div className="qty_bx">
                        <span
                          className="qty_minus"
                          onClick={this.updateChildtQty.bind(this, "decrement")}
                        >
                          -
                        </span>
                        <input
                          type="text"
                          className="proqty_input"
                          readOnly
                          value={this.state.childValue}
                        />
                        <span
                          className="qty_plus"
                          onClick={this.updateChildtQty.bind(this, "increment")}
                        >
                          +
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="error error_div_cls_limit">
                    Max limit reached
                  </div>
                  <div className="error error_div_cls">
                    Please Add Adult OR Child Count.
                  </div>

                  <div className="form-group">
                    <div className="special-instructions">
                      <textarea
                        placeholder="Eg. Window seat, Vegan, etc."
                        className="reservation_notes"
                        maxLength="500"
                        rows="3"
                        name="reservation_notes"
                        value={this.state.reservation_notes}
                        onChange={this.handleChange.bind(this)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="continue">
                      <a
                        href={void 0}
                        className="button"
                        onClick={this.submit_reservation.bind(this)}
                      >
                        continue
                      </a>
                    </div>
                  </div>
                  <br />
                  <p>
                    To make a reservation for Bankstown (Replay!), please visit{" "}
                    <b>
                      <a href="https://www.replaybankstown.com" target="_blank">
                        https://www.replaybankstown.com
                      </a>
                    </b>{" "}
                    <br />
                    <br />
                    To make a reservation for Blacktown (Dining Collective),
                    please visit{" "}
                    <b>
                      <a href="https://diningcollective.com.au" target="_blank">
                        https://diningcollective.com.au
                      </a>
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* innersection_wrap - end */}
        </div>
        {/* reservation-main-div - end */}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var outletsArr = Array();
  if (Object.keys(state.alloutlets).length > 0) {
    if (state.alloutlets[0].status === "ok") {
      outletsArr = state.alloutlets[0].result_set;
    }
  }
  return {
    globalsettings: state.settings,
    alloutletslist: outletsArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getRequestpage: (slug) => {
      dispatch({ type: GET_REQUESTPAGEDATA, slug });
    },

    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
  };
};
Reservation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Reservation)
);
