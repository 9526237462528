/* eslint-disable */
/* global google */
import React from "react";
class Autocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = { orderHandled: "" };
  }

  componentDidMount() {
    var input = document.getElementById("pac-input");
    var options = { componentRestrictions: { country: "au" } };

    var autocomplete = new google.maps.places.Autocomplete(input, options);
    var currnetThis = this;
    autocomplete.addListener("place_changed", function () {
      document.getElementById("location-error").style.display = "none";
      var place = autocomplete.getPlace();
      var latitude = place.geometry.location.lat();
      var longitude = place.geometry.location.lng();
      var locationAddress = place.formatted_address;
      var addressdetails = locationAddress + "~~" + latitude + "~~" + longitude;
      currnetThis.props.sateValChange("address", addressdetails);
    });
  }

  render() {
    return (
      <div className="pac-cardinfo" id="pac-card">
        <div id="pac-containerinfo">
          <input
            id="pac-input"
            className="location_address_filed"
            name="location_address"
            type="text"
            placeholder="Search Places ..."
          />
          <div id="location-error"></div>
        </div>
      </div>
    );
  }
}

export default Autocomplete;
