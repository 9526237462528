/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import cookie from "react-cookies";
import moment from "moment";

import tickImg from "../../common/images/tick.png";

class Reservationthankyou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagedetail: "",
      outletName: cookie.load("reservation_outlet_name"),
      number_of_adult: cookie.load("reservation_adult"),
      number_of_child: cookie.load("reservation_child"),
      reservation_date: cookie.load("reservation_show_date"),
      reservation_time: cookie.load("reservation_show_time"),
      reservation_end_time: cookie.load("reservation_end_time"),
      reservation_notes: cookie.load("reservation_notes"),
      reservation_order_on: cookie.load("RerservationOrderOn"),
      reservation_order_no: cookie.load("RerservationOrderlocalId"),
      reservation_submit: 1,
    };

    var orderlocalId =
      typeof cookie.load("RerservationOrderlocalId") === "undefined"
        ? ""
        : cookie.load("RerservationOrderlocalId");
    if (orderlocalId === "") {
      this.props.history.push("/");
    }
    cookie.save("orderTabs", "reservation", { path: "/" });
    cookie.remove("defaultAvilablityId");
  }

  clear_reservation_cookies() {
    cookie.remove("reservation_date");
    cookie.remove("reservation_show_date");
    cookie.remove("reservation_show_time");
    cookie.remove("reservation_time");
    cookie.remove("reservation_time_type");
    cookie.remove("reservation_adult");
    cookie.remove("reservation_child");
    cookie.remove("reservation_outlet_id");
    cookie.remove("reservation_outlet_name");
    cookie.remove("reservation_user_id");
    cookie.remove("reservation_user_fname");
    cookie.remove("reservation_user_lname");
    cookie.remove("reservation_user_name");
    cookie.remove("reservation_user_mobile");
    cookie.remove("reservation_user_email");
    cookie.remove("reservation_notes");
    cookie.remove("reservation_selected_outlet");
    cookie.remove("reservation_date_array");
    cookie.remove("RerservationOrderlocalId");
    cookie.remove("defaultAvilablityId");
    this.setState({ reservation_submit: 0 });
  }

  componentDidMount() {
    this.clear_reservation_cookies();
  }

  render() {
    return (
      <div>
        <Header />
        <div className="container common-top-div catering-thankyou-page">
          <div className="innersection_wrap tnk-you">
            <div className="mainacc_toptext tick">
              <img src={tickImg} />
              <h2>Thank You</h2>
              <p>Your reservation has been made</p>
              <p>we will contact you if there are any changes.</p>
            </div>

            <div className="thank-order-detaildiv">
              <div className="tnk-detail text-center">
                <h2>YOUR RESERVATION DETAILS</h2>
                <div className="tnk-order">
                  <h3>Reservation No - {this.state.reservation_order_no}</h3>
                  <p>
                    Reservation placed at : {this.state.reservation_order_on}
                  </p>
                </div>
              </div>

              <div className="tnk-delivery">
                <div className="delivery-cart-div">
                  <div className="cart_row cart-header-first">
                    <div className="reservation_order_details">
                      <div className="reservation_outlet">
                        <h4>Outlet for Reservation orders only</h4>
                        <p>{this.state.outletName}</p>
                      </div>
                      <br></br>
                      <div className="reservation_user">
                        <h4>NUMBER OF PAX</h4>
                        <p>
                          {this.state.number_of_adult} Adult &{" "}
                          {this.state.number_of_child} Child
                        </p>
                      </div>
                      <br></br>
                      <div className="reservation_Date">
                        <h4>DATE</h4>
                        <p>{this.state.reservation_date}</p>
                      </div>
                      <br></br>
                      <div className="reservation_Time">
                        <h4>TIME</h4>
                        <p>
                          {/* {this.state.reservation_time} */}
                          {moment(
                            this.state.reservation_time,
                            "HH:mm:ss"
                          ).format("hh:mm A")}{" "}
                          {this.state.reservation_end_time !== "" &&
                            this.state.reservation_end_time !== null && (
                              <>
                                -
                                {moment(
                                  this.state.reservation_end_time,
                                  "HH:mm:ss"
                                ).format("hh:mm A")}
                              </>
                            )}
                        </p>
                      </div>
                      <br></br>
                      <div className="reservation_notes">
                        <h4>SPECIAL INSTRUCTION</h4>
                        <p>{this.state.reservation_notes}</p>
                      </div>
                      <br></br>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tnk-chk-order">
                <Link className="button" to={"/myorders/reservation"}>
                  Check Booking Status
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Reservationthankyou;
