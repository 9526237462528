/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { appId, apiUrl } from "../../Helpers/Config";
import { stripslashes } from "../../Helpers/SettingHelper";
import promoone from "../../../common/images/promo1.jpg";
import promotwo from "../../../common/images/promo2.jpg";
import promothree from "../../../common/images/promo3.jpg";

import Slider from "react-slick";
const promoSetting = {
  slidesToShow: 3,
  slidesToScroll: 3,
  dots: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
class OurOutlets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whatshappening: [],
      whatshappeningcommon: "",
    };
    this.loadwhatshappening();
  }
  loadwhatshappening() {
    axios.get(apiUrl + "cms/whats_happening?app_id=" + appId).then((res) => {
      if (res.data.status === "ok") {
        this.setState({
          whatshappening: res.data.result_set,
          whatshappeningcommon: res.data.common,
        });
      } else {
      }
    });
  }
  render() {
    return (
      <>
        <div className="news-promo">
          {/* <div className="container">
            {this.props.ourpromotions}
            <Slider {...promoSetting}>
              <div className="promo-item">
                <a href={void 0} className="promo-claim">
                  <img src={promoone} alt="" />
                </a>
              </div>
              <div className="promo-item">
                <a href={void 0} className="promo-claim">
                  <img src={promotwo} alt="" />
                </a>
              </div>
              <div className="promo-item">
                <a href={void 0} className="promo-claim">
                  <img src={promothree} alt="" />
                </a>
              </div>
              <div className="promo-item">
                <a href={void 0} className="promo-claim">
                  <img src={promoone} alt="" />
                </a>
              </div>
              <div className="promo-item">
                <a href={void 0} className="promo-claim">
                  <img src={promotwo} alt="" />
                </a>
              </div>
              <div className="promo-item">
                <a href={void 0} className="promo-claim">
                  <img src={promothree} alt="" />
                </a>
              </div>
            </Slider>
          </div> */}
        </div>

        {this.state.whatshappening.length > 0 &&
          this.state.whatshappening.map((item, index) => {
            return (
              <div className="news-promo" key={index}>
                <h3 className="promo-title">{stripslashes(item.wh_name)}</h3>
                {item.gallery_image.length > 0 && (
                  <Slider {...promoSetting}>
                    {item.gallery_image.map((imgitem, imgindex) => {
                      return (
                        <div className="promo-item" key={imgindex}>
                          <a href={void 0} className="promo-claim">
                            <img
                              src={
                                this.state.whatshappeningcommon.image_source +
                                imgitem.gallery_image
                              }
                            />
                          </a>
                        </div>
                      );
                    })}
                  </Slider>
                )}
              </div>
            );
          })}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(OurOutlets);
