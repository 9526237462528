import { takeEvery, call, put } from "redux-saga/effects";
import { GET_INSTA_FEED, SET_INSTA_FEED } from "../actions";
import { instaToken } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetInstafeed = function* () {
  yield takeEvery(GET_INSTA_FEED, workerGetInstafeed);
};

function* workerGetInstafeed() {
  try {
    var fields =
      "id,media_type,media_url,thumbnail_url,timestamp,permalink,caption";
    var limit = "20";

    const uri =
      "https://graph.instagram.com/me/media?fields=" +
      fields +
      "&access_token=" +
      instaToken +
      "&limit=" +
      limit;

    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_INSTA_FEED, value: resultArr });
  } catch {
    console.log("Get Instagram Failed");
  }
}
