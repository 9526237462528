/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import { stripslashes } from "../../Helpers/SettingHelper";
import { GET_BANNER_LIST } from "../../../actions";
import mainbanner from "../../../common/images/banner.jpg";

var Parser = require("html-react-parser");

class HomeBanner extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.getBannerList();
  }

  componentDidMount() {
    /*console.log('DidMount');*/
  }

  /* componentDidUpdate() {
		console.log('this called last - after page loading');
	  } */

  render() {
    let bannerArr = this.props.banner;
    let bannerlist = [];
    let bannerimagesource = "";

    if (Object.keys(bannerArr).length > 0) {
      if (bannerArr[0].status === "ok") {
        bannerlist = bannerArr[0].result_set;
        bannerimagesource = bannerArr[0].common.image_source;
      }
    }

    var settingsGallery = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <>
       {Object.keys(bannerlist).length > 0 ? (
      <section className="main-banner">
         {bannerlist.map((banner, index) => {
        return(
            <div key={index}>
              <img className="fullstrech"  src={bannerimagesource + banner.banner_image} />
              {banner.banner_description != "" ? Parser(stripslashes(banner.banner_description)): ""}         
            </div>
          );
         })}
        </section>
        ) : (
        <section className="main-banner">
          <div className="loader-main-cls">
              <div className="loader-sub-div"></div>
            </div>
        </section>
        )}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    banner: state.banner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerList: () => {
      dispatch({ type: GET_BANNER_LIST });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(HomeBanner);
