/* eslint-disable */
import React, { Component } from "react";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { stripslashes, timeToConv12 } from "../Helpers/SettingHelper";

import { deliveryId, mediaUrl, CountryTxt } from "../Helpers/Config";
import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { connect } from "react-redux";
import noimage from "../../common/images/noimg-470x240.jpg";
import { GET_ALL_OUTLETS, GET_GLOBAL_SETTINGS } from "../../actions";

class Pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outlets: [],
      globalsettings: [],
      starttime: "",
      endtime: "",
    };
  }

  componentDidMount() {
    this.props.getAllOutlets(deliveryId);
    this.props.getGlobalSettings();
    $("html, body").animate({ scrollTop: 0 }, 800);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.outlets !== this.props.outlets) {
      if (nextProps.outlets && nextProps.outlets[0].status == "ok") {
        $("#dvLoading").fadeOut(2000);
        this.setState({
          outlets: nextProps.outlets[0].result_set,
        });
      }
    }

    if (nextProps.globalsettings !== this.props.globalsettings) {
      if (
        nextProps.globalsettings &&
        nextProps.globalsettings[0].status == "ok"
      ) {
        this.setState({
          starttime: nextProps.globalsettings[0].result_set.client_start_time,
          endtime: nextProps.globalsettings[0].result_set.client_end_time,
        });
      }
    }
  }

  callPhoneOptn(phoneTxt) {
    var resultTxt = "";
    if (phoneTxt !== "" && phoneTxt !== null) {
      if (phoneTxt.indexOf("+65") !== -1) {
        resultTxt = "tel:" + phoneTxt;
      } else if (phoneTxt.indexOf("65") !== -1 && phoneTxt.length >= 10) {
        resultTxt = "tel:+" + phoneTxt;
      } else {
        resultTxt = "tel:+65" + phoneTxt;
      }
    } else {
      resultTxt = "javascript:void(0);";
    }

    return resultTxt;
  }

  getOutletData = (dataProp) => {
    if (dataProp) {
      return dataProp.map((item, index) => {
        return (
          <li key={index}>
            <div className="ourrest_row">
              <div className="ourrest_img">
                {item.outlet_image !== "" ? (
                  <img
                    src={mediaUrl + "outlet/" + item.outlet_image}
                    alt="Outlet Img"
                  />
                ) : (
                  <img className="media-object" src={noimage} />
                )}
              </div>
              <div className="ourrest_info">
                <h4>{stripslashes(item.outlet_name)}</h4>
                <p>{item.outlet_address_line1}</p>
                <p>
                  {item.outlet_unit_number2 !== ""
                    ? "#" +
                      item.outlet_unit_number1 +
                      "-" +
                      item.outlet_unit_number2
                    : item.outlet_unit_number1}{" "}
                  {CountryTxt} ( {item.outlet_postal_code} )
                </p>
                <div>
                  {item.outlet_email !== "" && (
                    <span>
                      Email:{" "}
                      <a href={"mailto:" + item.outlet_email}>
                        {item.outlet_email}
                      </a>{" "}
                      <br></br>
                    </span>
                  )}
                  {item.outlet_phone !== "" && (
                    <>
                      Phone:{" "}
                      <span className="phone-show-dskp">
                        <a href={"tel::" + item.outlet_phone}>
                          {item.outlet_phone}
                        </a>
                      </span>
                      <span className="phone-show-mbl">
                        {" "}
                        <a href={this.callPhoneOptn(item.outlet_phone)}>
                          {item.outlet_phone}
                        </a>
                      </span>{" "}
                      <br></br>
                    </>
                  )}
                  <div>
                    {" "}
                    <strong>Operation Hours : </strong>
                    <br />
                    {item.da_monday_end_time !== null &&
                    item.da_monday_start_time !== null ? (
                      <>
                        <i className="fa fa-clock-o" aria-hidden="true"></i>{" "}
                        Mon:{" "}
                        {timeToConv12(item.da_monday_start_time) +
                          " - " +
                          timeToConv12(item.da_monday_end_time)}
                      </>
                    ) : (
                      ""
                    )}
                    {item.da_tuesday_end_time !== null &&
                    item.da_tuesday_start_time !== null ? (
                      <>
                        <br />
                        <i
                          className="fa fa-clock-o"
                          aria-hidden="true"
                        ></i>{" "}
                        Tue:{" "}
                        {timeToConv12(item.da_tuesday_start_time) +
                          " - " +
                          timeToConv12(item.da_tuesday_end_time)}
                      </>
                    ) : (
                      ""
                    )}
                    {item.da_wednesday_start_time !== null &&
                    item.da_wednesday_end_time !== null ? (
                      <>
                        <br />
                        <i
                          className="fa fa-clock-o"
                          aria-hidden="true"
                        ></i>{" "}
                        Wed:{" "}
                        {timeToConv12(item.da_wednesday_start_time) +
                          " - " +
                          timeToConv12(item.da_wednesday_end_time)}
                      </>
                    ) : (
                      ""
                    )}
                    {item.da_thursday_start_time !== null &&
                    item.da_tuesday_end_time !== null ? (
                      <>
                        <br />
                        <i
                          className="fa fa-clock-o"
                          aria-hidden="true"
                        ></i>{" "}
                        Thu:{" "}
                        {timeToConv12(item.da_thursday_start_time) +
                          " - " +
                          timeToConv12(item.da_tuesday_end_time)}
                      </>
                    ) : (
                      ""
                    )}
                    {item.da_friday_start_time !== null &&
                    item.da_monday_end_time !== null ? (
                      <>
                        <br />
                        <i
                          className="fa fa-clock-o"
                          aria-hidden="true"
                        ></i>{" "}
                        Fri:{" "}
                        {timeToConv12(item.da_friday_start_time) +
                          " - " +
                          timeToConv12(item.da_monday_end_time)}
                      </>
                    ) : (
                      ""
                    )}
                    {item.da_saturday_start_time !== null &&
                    item.da_saturday_end_time !== null ? (
                      <>
                        <br />
                        <i
                          className="fa fa-clock-o"
                          aria-hidden="true"
                        ></i>{" "}
                        Sat:{" "}
                        {timeToConv12(item.da_saturday_start_time) +
                          " - " +
                          timeToConv12(item.da_saturday_end_time)}
                      </>
                    ) : (
                      ""
                    )}
                    {item.da_sunday_start_time !== null &&
                    item.da_sunday_end_time !== null ? (
                      <>
                        <br />
                        <i
                          className="fa fa-clock-o"
                          aria-hidden="true"
                        ></i>{" "}
                        Sun:{" "}
                        {timeToConv12(item.da_sunday_start_time) +
                          " - " +
                          timeToConv12(item.da_sunday_end_time)}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <ul className="ourrest_infolinks">
                  <li className="media-links-b li-full-width">
                    <a
                      href="/menu"
                      rel="nofollow"
                      className="readmore font-headings"
                    >
                      <i className="fa fa-cutlery"></i>Make A Order Now{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        );
      });
    }
  };

  render() {
    return (
      <div className="outletList-main-div">
        {/* Header start */}
        <Header />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>Our Locations</p>
          </div>
        </div>

        <div className="outlet-lst-page">
          <div className="container-one cms-content">
            <div className="container">
              <ul className="outletul">
                {this.getOutletData(this.state.outlets)}
              </ul>
            </div>
          </div>
        </div>

        <Footer />
        <div id="dvLoading1234"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    outlets: state.alloutlets,
    globalsettings: state.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};
Pages.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pages));
