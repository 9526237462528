/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import footerLogo from "../../common/images/f-logo.png";
import instagram from "../../common/images/instagram.png";
import facebook from "../../common/images/facebook.png";
import youtube from "../../common/images/youtube.png";
import email from "../../common/images/foot-email.png";
import phone from "../../common/images/foot-telephone.png";
import location from "../../common/images/foot-placeholder.png";
import fflower from "../../common/images/footer-lhs-img.png";
import fflower1 from "../../common/images/footer-rhs-img.png";
import reserveImg from "../../common/images/cg-calender.png";
import { GET_STATIC_BLOCK, GET_GLOBAL_SETTINGS } from "../../actions";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import { addressFormat } from "../Helpers/SettingHelper";
import { projectTitle, CountryTxt } from "../Helpers/Config";
var Parser = require("html-react-parser");
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { footerBlocks: "", footerlink: "", globalsettings: [] };
    this.props.getGlobalSettings();
  }

  componentDidMount() {
    this.props.getStaticBlock();

    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.footerBlocks) {
      var footerPas = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "footer-contents") {
            footerPas = data.staticblocks_description;
            return "";
          }
        });
      }
      footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      this.setState({
        footerBlocks: PropsData.staticblack,
        footerlink: footerPas,
      });
    }
    if (this.state.globalsettings !== PropsData.globalsettings) {
      if (PropsData.globalsettings.length > 0) {
        this.setState({
          globalsettings: PropsData.globalsettings[0].result_set,
        });
      }
    }
  }
  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();

    return (
      <footer className="footer-main">
        <div className="container-full">
          <div className="footer-top">
            {this.state.globalsettings !== "" && (
              <div className="footer-top-inner">
                <div className="location-details my-account-foot">
                  <h5>My Account</h5>
                  <ul>
                    <li>
                      <a href="/myorders">Orders</a>
                    </li>
                    <li>
                      <a href="/rewards">Rewards</a>
                    </li>
                  </ul>
                </div>
                <div className="location-details">
                  <div className="location-icon">
                    <img src={location} alt="location" />
                  </div>
                  <div className="addr-dtls">
                    <h5>Address</h5>
                    <p>
                      {addressFormat(
                        this.state.globalsettings.client_unit_number1,
                        this.state.globalsettings.client_unit_number2,
                        this.state.globalsettings.client_address_line1,
                        this.state.globalsettings.client_address_line2,
                        this.state.globalsettings.client_postal_code
                      )}
                    </p>
                  </div>
                </div>
                <div className="location-details">
                  <div className="location-icon">
                    <img src={phone} alt="phone" />
                  </div>
                  <div className="addr-dtls">
                    <h5>Hotline</h5>

                    <p>
                      <a
                        href={
                          "tel:" +
                          this.state.globalsettings.client_company_phone
                        }
                      >
                        {this.state.globalsettings.client_company_phone}
                      </a>{" "}
                    </p>
                  </div>
                </div>
                <div className="location-details">
                  <div className="location-icon">
                    <img src={email} alt="email" />
                  </div>
                  <div className="addr-dtls">
                    <h5>Email</h5>

                    <p>
                      <a
                        href={
                          "mailto:" +
                          this.state.globalsettings.client_email_address
                        }
                      >
                        {this.state.globalsettings.client_email_address}
                      </a>
                    </p>
                  </div>
                </div>
                <div className="location-details">
                  <div className="f-socail">
                    <div className="f-socail-btm">
                      <h5>Follow Us </h5>
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/ChefsGalleryRestaurant"
                            target="_blank"
                          >
                            <img
                              src={facebook}
                              className="facebook"
                              alt="facebook"
                            />
                          </a>{" "}
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/chefsgallery/"
                            target="_blank"
                          >
                            <img
                              src={instagram}
                              className="instagram"
                              alt="instagram"
                            />
                          </a>{" "}
                        </li>
                        <li>
                          <a href="https://www.youtube.com" target="_blank">
                            <img
                              src={youtube}
                              className="youtube"
                              alt="youtube"
                            />
                          </a>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="mobile-footer-nav">
          <Accordion>
            <AccordionItem title="Contact Us">
              <ul>
                <li>
                  <img alt="" src="/img/foot-placeholder.png" />
                  <p>
                    46 Race Course Rd.
                    <br />
                    {CountryTxt}&nbsp; 218559
                  </p>
                </li>
                <li>
                  <img alt="" src="/img/foot-telephone.png" />{" "}
                  <a href="tel:9090 2526">9090 2526</a>
                </li>
                <li>
                  <img alt="" src="/img/foot-email.png" />{" "}
                  <a href="mailto:info@chefsgallery.sg">info@chefsgallery.sg</a>
                </li>
              </ul>
            </AccordionItem>

            <AccordionItem title="My Account">
              <ul>
                <li>
                  <a href="/myorders">Orders</a>
                </li>
                <li>
                  <a href="/rewards">Rewards</a>
                </li>
              </ul>
            </AccordionItem>
          </Accordion>
        </div>
        <div className="copyright-section">
          <p>
            Copyright {yearSp} {projectTitle}. All rights reserved.
          </p>
        </div>

        <div className="scrolltop" id="scrollbutton">
          <a href="/" className="disbl_href_action">
            <span>
              <i className="fa fa-angle-double-up"></i>
            </span>
          </a>
        </div>
        <div className="fb-flower-lhs">
          {" "}
          <img src={fflower} />
        </div>
        <div className="fb-flower-rhs">
          {" "}
          <img src={fflower1} />
        </div>
        <div className="floating-reservation">
          <Link to="/reservation">
            <img src={reserveImg} alt="Reservation" />
            <span>Reservation</span>
          </Link>
        </div>
      </footer>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
    globalsettings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
